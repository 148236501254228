import React from 'react';
import { IMaskInput } from 'react-imask';

const CreditCardInputMasked = React.forwardRef(function CreditCardInputMasked(props, ref) {
    const { onChange, name, ...other } = props;

    const mask = [
        {
            mask: '0000 0000 0000 0000',
            maxLength: 19,
        },
    ];

    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            mask={mask}
            value={props.value?.toString() || ''}
            unmask={true} // Retorna apenas os números sem formatação
            onAccept={newValue => {
                // Envia um evento compatível com React
                onChange({
                    target: {
                        name, // Nome do campo
                        value: newValue, // Valor atualizado
                    },
                });
            }}
        />
    );
});

export default CreditCardInputMasked;
