import { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { bytesToSize } from '../services/helpers';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { FormHelperText, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

const mimeTypes = ['image/jpg', 'image/png', 'image/jpeg'];

export default function AddImage({ onChange, value, label, colorLabel, name, canvaWidth, canvaHeight }) {
    const refInputFile = useRef(null);

    const [preview, setPreview] = useState();
    const [image, setImage] = useState();

    const handleFile = data => {
        if (!data) return;
        if (!mimeTypes.includes(data.type)) {
            alert('Tipo de arquivo não suportado');
            return;
            //return dialogs.alert({title: 'Formato não suportado', body:'Tente usar arquivos nos formatos PDF, JPEG ou PNG'})
        }
        if (data.size > 5000000) {
            alert(`${bytesToSize(data.size)} ${data.size} Arquivo maior que 5Mb. Tente reduzir o arquivo.`);
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
            const base64 = reader.result.replace(/^data:image\/(?:gif|png|jpeg|jpg|bmp|webp)(?:;charset=utf-8)?;base64,/, '');
            setImage(base64);
            setPreview(URL.createObjectURL(data));
        };
        reader.onerror = function (error) {
            alert('Erro ao inserir a imagem');
        };
    };

    const handleOpen = () => {
        if (refInputFile.current) {
            console.log(refInputFile.current.click);
            refInputFile.current.click();
        }
    };

    return (
        <Stack gap={1} sx={{ mb: 2 }}>
            <Stack alignItems="center">
                {value ? (
                    <>
                        <Box
                            component="img"
                            src={value}
                            sx={{
                                marginLeft: 'auto',
                                borderRadius: 1,
                                marginRight: 'auto',
                                width: canvaWidth || 300,
                                height: canvaHeight || 220,
                                textAlign: 'center',
                                objectFit: 'cover',
                                position: 'relative'
                            }}
                        />
                        <Button variant="text" onClick={handleOpen}>
                            Trocar imagem
                        </Button>
                        <Button
                            variant="text"
                            onClick={e => {
                                onChange('previewImage', null);
                                onChange(name, null);
                            }}
                        >
                            Remover imagem
                        </Button>
                    </>
                ) : (
                    <Box
                        sx={{
                            border: 'dashed 2px #ccc',
                            borderRadius: 1,
                            width: canvaWidth || 300,
                            height: canvaHeight || 220,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <Button onClick={handleOpen}>Adicionar imagem</Button>
                    </Box>
                )}
            </Stack>
            <FormHelperText error sx={{ textAlign: 'center', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                {label ? label : 'Dimensões recomentadas: 200x200 pixels'}
            </FormHelperText>

            <TextField
                inputRef={refInputFile}
                type="file"
                id="file"
                multiple={false}
                accept="image/jpeg,image/png,image/jpg"
                name="logo"
                onChange={e => handleFile(e.target.files[0])}
                style={{ display: 'none' }}
            />

            <Dialog open={Boolean(preview)} fullWidth maxWidth="sm">
                <DialogTitle>Pré-visualização</DialogTitle>
                <Divider />
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Box sx={{ maxHeight: 400, maxWidth: 500 }} component="img" src={preview} />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={e => {
                            onChange('previewImage', preview);
                            onChange(name, image);
                            setPreview();
                            setImage();
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
