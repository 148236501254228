import { ChevronLeft } from '@mui/icons-material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVert from '@mui/icons-material/MoreVert';
import PercentIcon from '@mui/icons-material/Percent';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Avatar, Card, CardContent, ListItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown';
import { LoadingContent } from '../../../components/Loading';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import DialogAdvertiser from './components/DialogAdvertiser';
import ListBranches from './components/ListBranches';
import ListOffers from './components/ListOffers';
import useErrors from '../../../hooks/useErrors';
import server from '../../../services/server';
import ConfirmationModal from '../../../components/ConfirmationModal';

export default function AdvertiserDetails() {
    const { id } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`advertiser/${id}`);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const {setErrors, enqueueSnackbar} = useErrors()
    const navigate = useNavigate()

    const [tab, setTab] = useState('#tab-1');
    const { name, handleOpen } = useDialog();

    if (isFetching) return <LoadingContent />;
    if (error) return <>Error: {error?.message}</>;
    if (!data) return <>Tente novamente mais tarde</>;

    const handleDelete = () => {
        setConfirmAction(() => async () => {
            try {
                await server.delete(`advertiser/${id}`);
                enqueueSnackbar('Anunciante deletado com sucesso!');
                navigate('/dashboard/anunciantes'); // Redireciona para a lista de anunciantes
            } catch (error) {
                setErrors('Erro ao deletar anunciante.', error);
            }
            setConfirmOpen(false);
        });
        setConfirmOpen(true);
    };

    return (
        <>
            <Container sx={{ gap: 3, my: 4, flexDirection: 'column', display: 'flex' }}>
                <Stack alignItems="center" direction="row" columnGap={1} py={3}>
                    <IconButton component={Link} to={-1} replace={true}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography variant="h2">Detalhes do anunciante</Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack direction="row" alignItems="center" columnGap={2} pb={2}>
                            <Avatar
                                variant='rounded'
                                sx={{ border: '1px solid #eee', objectFit: 'cover', width: 70, height:70 }}
                                src={data.image}
                            />
                            <div>
                                <Typography variant="h4">{data.name}</Typography>
                                <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                                    {data.businessName}
                                </Typography>
                            </div>

                            <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
                                <Dropdown>
                                    <IconButton>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu placement="bottom-end">
                                        <MenuItem component={Link} to={`#advertiser|${id}`}>
                                            Alterar dados
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem color="danger" onClick={handleDelete}>Excluir anunciante</MenuItem>
                                        <Divider />
                                        <ListItem color="danger" sx={{ fontSize: 10 }}>
                                            ID Legado: {data.originId}
                                        </ListItem>
                                    </Menu>
                                </Dropdown>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Grid container columnGap={2} pt={2}>
                            <Grid item xs={12} sm={6} md textAlign="center">
                                <Typography variant="title-sm">Status</Typography>
                                <div>
                                    <Chip
                                        size="small"
                                        label={data.status ? 'Ativo' : 'Inativo'}
                                        color={data.status ? 'success' : 'error'}
                                        icon={<FiberManualRecordIcon htmlColor="green" />}
                                    />
                                </div>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Plano</Typography>
                                <Typography variant="subtitle-sm"> {data.plan ? data.plan.name : '--'} </Typography>
                            </Grid>
                            {/* <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Tipo</Typography>
                                <Typography variant="subtitle-sm"> -- </Typography>
                            </Grid> */}
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Responsável</Typography>
                                <Typography variant="subtitle-sm">{data?.responsibleName || '--'}</Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Contato</Typography>
                                <Typography variant="subtitle-sm">{data?.responsibleEmail || '--'}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <TabContext value={tab}>
                    <TabList onChange={(e, tab) => setTab(tab)}>
                        <Tab icon={<PercentIcon />} iconPosition="start" label="Ofertas" value="#tab-1" />

                        {/* <Tab icon={<StorefrontIcon />} iconPosition="start" label="Produtos" value="#tab-2" /> */}

                        <Tab icon={<CorporateFareIcon />} iconPosition="start" label="Filiais" value="#tab-3" />
                    </TabList>
                    <TabPanel value="#tab-1" sx={{ p: 0 }}>
                        <ListOffers />
                    </TabPanel>
                    <TabPanel value="#tab-2" sx={{ p: 0 }}></TabPanel>
                    <TabPanel value="#tab-3" sx={{ p: 0 }}>
                        <ListBranches />
                    </TabPanel>
                </TabContext>

                <DialogAdvertiser mutate={mutate} />
                <ConfirmationModal
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={confirmAction}
                title="Confirmação de Exclusão"
                message="Tem certeza de que deseja excluir este anunciante?"
            />
            </Container>
        </>
    );
}
