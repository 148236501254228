import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import useErrors from '../hooks/useErrors';
import ZipCodeInputMasked from './ZipCodeInputMasked';

export default function Address({ address, setFieldError, setFieldValue, ...rest }) {
    const { value } = rest || {};
    const { enqueueSnackbar } = useErrors();
    const [searchingZipCode, setSearching] = useState(false);

    // Função de busca do CEP
    const searchPostalCode = useCallback(
        async e => {
            try {
                setSearching(true);

                //const cleanedCep = value.replace(/\D/g, ''); // Antes de fazer a busca o valor é limpo.

                if (Boolean(value) && value.length === 8) {
                    const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

                    if (data.erro) throw new Error('CEP não encontrado');

                    setFieldValue('street', data.logradouro);
                    setFieldValue('neighborhood', data.bairro);
                    setFieldValue('city', data.localidade);
                    setFieldValue('state', data.uf);
                    setFieldValue('address', `${data.logradouro}, ${data.bairro}, ${data.localidade}-${data.uf}`);

                    setSearching(false);
                }
                //  else {
                //     throw new Error('CEP inválido');
                // }
            } catch (error) {
                setSearching(false);
                // setErrors(error);
                enqueueSnackbar('CEP Inválido', { variant: 'error' });
            }
        },
        [value],
    );

    return (
        <div>
            <TextField
                fullWidth
                label="Digite o CEP"
                // onChange={onChange}
                // value={formatCep(value)} // Formatação CEP
                // name={name}
                {...rest}
                //inputProps={{ maxLength: 9 }}
                onBlur={searchPostalCode}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={searchPostalCode} disabled={searchingZipCode}>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputComponent: ZipCodeInputMasked,
                }}
            />
            {address && <FormHelperText>{address}</FormHelperText>}
        </div>
    );
}
