import React from 'react';
import { IMaskInput } from 'react-imask';

// Componente CommissionField para aplicar a máscara de preço
const CommissionField = React.forwardRef(function CommissionField(props, ref) {
    const { onChange, value, ...other } = props;

    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            value={String(value)} // Garante que o valor seja sempre uma string
            onAccept={value => onChange(props.name, value)} // Atualiza o valor com onChange
            mask={'R$ num'} // Define a máscara de preço
            unmask={true} // Remove a máscara ao retornar o valor para o estado
            blocks={{
                num: {
                    mask: Number,
                    scale: 4, // 4 casas decimais
                    thousandsSeparator: '.',
                    radix: ',', // Separador decimal
                    min: 0,
                    max: 10000,
                },
            }}
        />
    );
});

// Componente PriceMask que usa o CommissionField
export function PriceMask({ value, onChange, name }) {
    return (
        <CommissionField
            name={name} // Passa o nome do campo para o CommissionField
            value={value} // Passa o valor para o CommissionField
            onChange={onChange} // Passa a função onChange para o CommissionField
        />
    );
}

export const PriceInputMasked = React.forwardRef(function PriceInputMasked(props, ref) {
    const { onChange, name, ...other } = props;

    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            value={props.value?.toString() || ''} // Garante que o valor seja sempre uma string
            onAccept={newValue =>
                onChange({
                    target: {
                        name, // Nome do campo
                        value: newValue, // Valor atualizado
                    },
                })
            } // Atualiza o valor com onChange
            mask={'R$ num'} // Define a máscara de preço
            unmask={true} // Remove a máscara ao retornar o valor para o estado
            blocks={{
                num: {
                    mask: Number,
                    scale: 4, // 4 casas decimais
                    thousandsSeparator: '.',
                    radix: ',', // Separador decimal
                    min: 0,
                    max: 10000,
                },
            }}
        />
    );
});
