import axios from 'axios';
import Cookies from 'universal-cookie';

const SERVER = process.env.REACT_APP_SERVER;
const cookies = new Cookies();

const server = axios.create({
    baseURL: SERVER,
    timeout: 20000,
    timeoutErrorMessage: 'Servidor Instável. Tente mais tarde.',
});

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
    refreshSubscribers.forEach(callback => callback(token));
    refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
    refreshSubscribers.push(callback);
}

server.interceptors.request.use(async config => {
    const accessToken = cookies.get('token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

function getRefreshToken() {
    const cookies = new Cookies();
    return cookies.get('refreshtoken');
}

// interceptando cada resposta para verificar se houver erro de autenticação
server.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        
        if (error.response?.status === 401 && !originalRequest?._retry) {
            if (isRefreshing) {
                return new Promise(resolve => {
                    addRefreshSubscriber(token => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        resolve(server(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                
                const refreshtoken = cookies.get('refreshtoken');
                const response = await axios.post(`${SERVER}auth/refresh-token`, null, {
                    headers: {
                        refreshtoken: refreshtoken,
                    },
                });

                const newAccessToken = response.data.data.token;
                const club = cookies.get('club');

                if (club) {
                    cookies.set('token', newAccessToken, { path: '/clube' });
                } else {
                    cookies.set('token', newAccessToken, { path: '/dashboard' });
                }

                server.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
                onRefreshed(newAccessToken);
                return server(originalRequest);
            } catch (err) {
                console.log(err)
                cookies.remove();
                throw error;
                // return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        } else {
            throw error;
        }
    },
);
export default server;
