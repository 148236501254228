import { Link, useNavigate } from 'react-router-dom';
import { Close, DocumentScannerRounded, DrawRounded, Share, ShareRounded } from '@mui/icons-material';
import {
    Button,
    TextField,
    Container,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Card,
    FormHelperText,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Tab,
    CardContent,
    Box,
    Alert,
} from '@mui/material';
import { replace, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Address from '../../../components/Address';
import useDialog from '../../../hooks/useDialog';
import useErrors from '../../../hooks/useErrors';
import { useFetch } from '../../../hooks/useFecth';
import server from '../../../services/server';
import DocumentInputMasked from '../../../components/DocumentInputMasked';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import * as Yup from 'yup';
import SelectPlan from '../../../components/SelectPlan';
import { PriceInputMasked } from '../../../components/PriceMask';
import { payment_status } from '../../../services/helpers';
import PhoneInputMasked from '../../../components/PhoneInputMasked';
import { useConfirm } from 'material-ui-confirm';

function Edit({ id }) {
    const { data, mutate, isValidating } = useFetch(`subscription/${id}`);
    return <Form data={data} mutate={mutate} />;
}

const schema = Yup.object().shape({
    document: Yup.string().required('Documento é obrigatório'),
    companyName: Yup.string().required('Razão Social é obrigatório'),
    tradeName: Yup.string().required('Nome Fantasia é obrigatório'),
    stateRegistration: Yup.string().required('Inscrição Estadual é obrigatório'),
    zipcode: Yup.string().required('CEP é obrigatório'),
    address: Yup.string().required('Endereço é obrigatório'),
    number: Yup.string().required('Número é obrigatório'),
    complement: Yup.string().required('Complemento é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),

    contractorName: Yup.string().required('Nome do Contratante é obrigatório'),
    contractorName: Yup.string().required('Nome do Contratante é obrigatório'),
    contractorDocument: Yup.string().required('Documento do Contratante é obrigatório'),
    contractorNationality: Yup.string().required('Nacionalidade do Contratante é obrigatório'),
    contractorPhone: Yup.string().required('Telefone do Contratante é obrigatório'),
    // contractorBirthDate: Yup.string().required('Data de Nascimento do Contratante é obrigatório'),
    // contractorGender: Yup.string().required('Gênero do Contratante é obrigatório'),
    contractorPosition: Yup.string().required('Cargo do Contratante é obrigatório'),
    contractorEmail: Yup.string().required('Email do Contratante é obrigatório'),
    ///email: Yup.string().required('Email é obrigatório'),
    //position: Yup.string().required('Cargo é obrigatório'),
    //phone: Yup.string().required('Telefone é obrigatório'),
    //mobile: Yup.string().required('Celular é obrigatório'),
    // value: Yup.string().required('Mensalidade é obrigatório'),
    // planId: Yup.string().required('Plano é obrigatório'),
    // paymentMethod: Yup.string().required('Forma de Pagamento é obrigatório'),
    // paymentDay: Yup.string().required('Dia de Pagamento é obrigatório'),
});

function Termo({ mutate, order }) {
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, getFieldProps, setFieldValue, setValues, isSubmitting, errors, handleSubmit, setTouched, isValid, validateForm } = useFormik({
        initialValues: {
            document: '',
            companyName: '',
            tradeName: '',
            stateRegistration: '',
            isExempt: false,
            isSimpleOptant: false,
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            zipcode: '',

            contractorName: '',
            contractorDocument: '',
            contractorNationality: '',
            contractorPhone: '',
            contractorBirthDate: '',
            contractorGender: '',
            contractorPosition: '',
            contractorEmail: '',
            domain: '',

            paymentMethod: '',
            paymentDay: '',
        },
        validationSchema: schema,
        validateOnChange: true,
        //validateOnMount: true,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`subscription/${order.uuid}/term`, {
                    contractData: values,
                });
                mutate && (await mutate());
                setSubmitting(false);
                enqueueSnackbar('Termo de adesão armazenado.');
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        console.log(order?.orderData?.contract);
        if (Boolean(order?.orderData?.contract)) {
            setValues(order?.orderData?.contract, true);
            setTouched({}, false);
            validateForm();
        }
    }, [order]);

    const hasContract = Boolean(order?.orderData?.contractId);
    const hasTerm = Boolean(order?.orderData?.contract);
    const hasSignature = Boolean(order?.Contract?.signedAt);

    return (
        <Stack spacing={2}>
            <>
                <Typography variant="body1">
                    Após a geração do contrato, ele deverá ser assinado para formalizar sua adesão. O clube será efetivamente criado após a terceira
                    etapa, que consiste na confirmação do pagamento.
                </Typography>
                <Divider>Dados jurídicos</Divider>
                <TextField
                    label="CNPJ"
                    {...getFieldProps('document')}
                    fullWidth
                    InputProps={{
                        inputComponent: DocumentInputMasked,
                    }}
                    error={!!errors.document}
                    helperText={errors.document}
                />
                <TextField
                    label="Razão Social"
                    {...getFieldProps('companyName')}
                    fullWidth
                    error={!!errors.companyName}
                    helperText={errors.companyName}
                />
                <TextField label="Nome Fantasia" {...getFieldProps('tradeName')} fullWidth error={!!errors.tradeName} helperText={errors.tradeName} />
                <TextField
                    label="Inscrição Estadual"
                    {...getFieldProps('stateRegistration')}
                    fullWidth
                    error={!!errors.stateRegistration}
                    helperText={errors.stateRegistration}
                />
                <Address
                    {...getFieldProps('zipcode')}
                    setFieldValue={setFieldValue}
                    address={values?.address}
                    error={!!errors.zipcode}
                    helperText={errors.zipcode}
                />
                <Stack direction="row" columnGap={2}>
                    <TextField
                        fullWidth
                        label="Complemento"
                        {...getFieldProps('complement')}
                        error={!!errors.complement}
                        helperText={errors.complement}
                    />
                    <TextField sx={{ width: 150 }} label="Número" {...getFieldProps('number')} error={!!errors.number} helperText={errors.number} />
                </Stack>

                <Divider>Dados do responsável contratante</Divider>
                <TextField
                    label="Nome do Contratante"
                    {...getFieldProps('contractorName')}
                    fullWidth
                    error={!!errors.contractorName}
                    helperText={errors.contractorName}
                />
                <TextField
                    label="CPF do contratante"
                    {...getFieldProps('contractorDocument')}
                    fullWidth
                    InputProps={{
                        inputComponent: DocumentInputMasked,
                    }}
                    error={!!errors.contractorDocument}
                    helperText={errors.contractorDocument}
                />
                <TextField
                    label="Email"
                    {...getFieldProps('contractorEmail')}
                    fullWidth
                    error={!!errors.contractorEmail}
                    helperText={errors.contractorEmail}
                />
                <TextField
                    label="Nacionalidade"
                    {...getFieldProps('contractorNationality')}
                    fullWidth
                    error={!!errors.contractorNationality}
                    helperText={errors.contractorNationality}
                />
                <TextField
                    label="Cargo"
                    {...getFieldProps('contractorPosition')}
                    fullWidth
                    error={!!errors.contractorPosition}
                    helperText={errors.contractorPosition}
                />
                <TextField
                    label="Telefone"
                    {...getFieldProps('contractorPhone')}
                    fullWidth
                    error={!!errors.contractorPhone}
                    helperText={errors.contractorPhone}
                    InputProps={{
                        inputComponent: PhoneInputMasked,
                    }}
                />

                <Divider>Plano, mensalidade e pagamento</Divider>
                {order?.Order_products?.map(item => (
                    <Stack direction="row" spacing={2} key={item.id}>
                        <TextField
                            label="Nome"
                            inputProps={{ readyOnly: true }}
                            fullWidth
                            value={item.Product.name}
                            InputProps={{
                                readOnly: 'readonly',
                            }}
                        />
                        <TextField
                            label="Valor"
                            fullWidth
                            value={item.Product.price}
                            InputProps={{
                                readOnly: true,
                                inputComponent: PriceInputMasked,
                            }}
                        />
                    </Stack>
                ))}

                <Stack direction="row" spacing={2}>
                    <TextField
                        label="Valor da mensalidade"
                        fullWidth
                        value={order?.subtotal || 0}
                        InputProps={{
                            readOnly: true,
                            inputComponent: PriceInputMasked,
                        }}
                    />
                </Stack>
                {Boolean(order?.discount) && (
                    <>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Aplicar desconto"
                                fullWidth
                                value={order?.discount}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>

                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Valor a faturar"
                                fullWidth
                                value={order?.total}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>
                    </>
                )}

                {Boolean(order?.Suborders.length) && <Divider>Customizações e outros serviços</Divider>}
                {order?.Suborders?.map(subOrder => (
                    <React.Fragment key={subOrder.id}>
                        {subOrder?.Order_products?.map(item => (
                            <React.Fragment>
                                <Stack direction="row" spacing={2} key={item.id}>
                                    <TextField
                                        label="Nome"
                                        inputProps={{ readyOnly: true }}
                                        fullWidth
                                        value={item.Product.name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Valor"
                                        fullWidth
                                        value={item.Product.price}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>
                            </React.Fragment>
                        ))}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Valor total"
                                fullWidth
                                value={subOrder?.subtotal || 0}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>
                        {Boolean(subOrder?.discount) && (
                            <>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="Desconto"
                                        fullWidth
                                        value={subOrder?.discount || 0}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="Valor total"
                                        fullWidth
                                        value={subOrder?.total || 0}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>
                            </>
                        )}
                    </React.Fragment>
                ))}
                <Divider />
                <Button
                    variant="contained"
                    sx={{ alignSelf: 'flex-end' }}
                    disabled={!isValid || isSubmitting || hasSignature || hasContract}
                    onClick={handleSubmit}
                >
                    {!hasTerm ? 'Confirmar e salvar dados' : 'Reenviar dados do contrato'}
                </Button>
            </>

            {hasContract && (
                <>
                    <Divider />
                    <Alert severity="success">Contrato gerado com sucesso</Alert>
                </>
            )}
        </Stack>
    );
}

function Contract({ mutate, order }) {
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();
    const { values, getFieldProps, setFieldValue, setValues, isSubmitting, errors, handleSubmit, isValid } = useFormik({
        initialValues: {
            paymentDay: '10',
        },
        validateOnChange: true,
        validateOnMount: true,

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await confirm({
                    title: 'Assinatura do contrato',
                    description: `Você está gerando o contrato para assinatura digital. Os dados serão enviados para ${order.orderData.contract.contractorEmail}`,
                    confirmationText: 'Confirmo',
                    contentProps: { dividers: true },
                });
                await server.put(`subscription/${order.uuid}/contract`);
                await mutate();
                setSubmitting(false);
                enqueueSnackbar('Contrato gerado.');
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    // useEffect(() => {
    //     if (Boolean(order?.Contract?.contractData)) {
    //         setValues({ ...order?.Contract?.contractData }, true);
    //     }
    // }, [order]);

    const hasContract = Boolean(order?.orderData?.contractId);
    const hasTerm = Boolean(order?.orderData?.contract);
    const hasSignature = Boolean(order?.Contract?.signedAt);

    return (
        <>
            <Paper variant="outlined" sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="h3" component="h2">
                        Assinatura do contrato
                    </Typography>
                    {hasTerm && !hasContract && (
                        <>
                            <Divider />
                            <Typography variant="body1">
                                Você preencheu a proposta com os dados solicitados. Agora vamos parar a etapa onde você precisa gerar o contrato para
                                assinatura para assinatura digital.
                            </Typography>
                            <Card>
                                <CardContent>
                                    <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
                                        <DrawRounded fontSize="large" sx={{ fontSize: 70 }} color="action" />
                                        <Divider orientation="vertical" flexItem />
                                        <Typography variant="body1" textAlign="center">
                                            O contrato será enviado para <strong>{order.orderData.contract.contractorEmail}</strong>
                                        </Typography>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack direction="row" sx={{ width: 200 }} justifyContent="center">
                                            <Button
                                                sx={{ alignSelf: 'flex-end' }}
                                                variant="outlined"
                                                disabled={isSubmitting || hasSignature}
                                                onClick={handleSubmit}
                                            >
                                                Gerar contrato
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Divider />
                        </>
                    )}
                    {hasContract && !hasSignature && (
                        <>
                            <Divider />
                            <Typography variant="body1">
                                O contrato foi enviado para o email <strong>{order.orderData.contract.contractorEmail}</strong>. Ao assinar o contrato
                                o sistema será atualizado automaticamente.
                            </Typography>
                            <Alert severity="info">Aguardando a assinatura do contrato</Alert>
                        </>
                    )}
                    {hasContract && hasSignature && (
                        <>
                            <Divider />
                            <Alert severity="success">Contrato assinado com sucesso</Alert>
                        </>
                    )}
                </Stack>
            </Paper>
        </>
    );
}
function Pagamento({ mutate, order, title, subOrder }) {
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, getFieldProps, setValues, setFieldValue, isSubmitting, errors, handleSubmit, isValid } = useFormik({
        initialValues: {
            discount: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`subscription/${order.uuid}/order`, values);
                await mutate();
                setSubmitting(false);
                enqueueSnackbar('Order de pagamento alterada');
            } catch (error) {
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (order) {
            const orderTotal = !subOrder ? order?.total : subOrder?.total;
            const orderDiscount = !subOrder ? order?.discount : subOrder?.discount;
            const orderSubTotal = !subOrder ? order?.subtotal : subOrder?.subtotal;

            setValues({ discount: orderDiscount });
        }
    }, [order]);

    const waitingPayment = !subOrder ? ['PENDING'].includes(order?.paymentStatus) : ['PENDING'].includes(subOrder?.paymentStatus);

    const paid = !subOrder ? ['CONFIRMED', 'RECEIVED'].includes(order?.paymentStatus) : ['CONFIRMED', 'RECEIVED'].includes(subOrder?.paymentStatus);
    const notPaid = !subOrder
        ? ['WAITING', 'REPROVED_BY_RISK_ANALYSIS', 'AWAITING_RISK_ANALYSIS', 'ATTEMP', 'CREDIT_CARD_CAPTURE_REFUSED'].includes(order?.paymentStatus)
        : ['WAITING', 'REPROVED_BY_RISK_ANALYSIS', 'AWAITING_RISK_ANALYSIS', 'ATTEMP', 'CREDIT_CARD_CAPTURE_REFUSED'].includes(
              subOrder?.paymentStatus,
          );
    const orderStatus = !subOrder ? order?.paymentStatus : subOrder?.paymentStatus;
    const paymentData = !subOrder ? order?.paymentData : subOrder?.paymentData;

    const orderProducts = !subOrder ? order?.Order_products : subOrder?.Order_products;
    const orderTotal = !subOrder ? order?.total : subOrder?.total;
    const orderDiscount = !subOrder ? order?.discount : subOrder?.discount;
    const orderSubTotal = !subOrder ? order?.subtotal : subOrder?.subtotal;

    return (
        <>
            <Stack spacing={2}>
                <>
                    <Typography variant="body2">* A cobrança será faturada em nome da empresa contrante que contra no contrato.</Typography>

                    {orderProducts?.map(item => (
                        <Stack direction="row" spacing={2} key={item.id}>
                            <TextField
                                label="Nome"
                                inputProps={{ readyOnly: true }}
                                fullWidth
                                value={item.Product.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label="Valor"
                                fullWidth
                                value={Number(item.Product.price)}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>
                    ))}
                    <TextField
                        value={Number(orderSubTotal)}
                        label="Valor da mensalidade"
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            inputComponent: PriceInputMasked,
                        }}
                    />

                    <>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Desconto aplicado"
                                {...getFieldProps('discount')}
                                fullWidth
                                InputProps={{
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>

                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Valor a faturar"
                                fullWidth
                                value={orderSubTotal - values.discount}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: PriceInputMasked,
                                }}
                            />
                        </Stack>
                    </>

                    {waitingPayment && (
                        <>
                            <Divider />
                            {/* <Alert severity="warning">Aguardando pagamento</Alert> */}

                            {paymentData && paymentData.billingType === 'BOLETO' && (
                                <Stack direction="row" spacing={2}>
                                    <Card>
                                        <CardContent>
                                            <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
                                                <DocumentScannerRounded fontSize="large" sx={{ fontSize: 70 }} color="action" />
                                                <Divider orientation="vertical" flexItem />
                                                <Typography variant="body1" textAlign="center">
                                                    Seu boleto vence em ate 5 dias. Faça o pagamento para concluir a proposta.
                                                </Typography>
                                                <Divider orientation="vertical" flexItem />
                                                <Stack direction="row" sx={{ width: 200 }} justifyContent="center">
                                                    <Button variant="outlined" color="success" href={paymentData?.bankSlipUrl} target="_blank">
                                                        Ver Boleto
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            )}

                            {paymentData && paymentData.billingType === 'PIX' && (
                                <Stack spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        src={`data:image/png;base64,${paymentData?.chargePix?.encodedImage}`}
                                        alt="QrCode"
                                        width={200}
                                        height={200}
                                        border={1}
                                        borderColor="text.secondary"
                                        borderRadius={1}
                                    />
                                    <TextField
                                        fullWidth
                                        value={paymentData?.chargePix?.payload}
                                        label="Chave Pix"
                                        InputProps={{ readOnly: 'readonly' }}
                                    />
                                    <Button variant="outlined" target="_blank">
                                        Copiar codigo
                                    </Button>
                                </Stack>
                            )}
                        </>
                    )}

                    <Divider />
                    {paid && <Alert severity="success">Pagamento realizado com sucesso</Alert>}
                    {notPaid && (
                        <>
                            {' '}
                            <Alert severity="info">{payment_status[orderStatus]}</Alert>
                            <Divider />
                            <Button
                                sx={{ alignSelf: 'flex-end' }}
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            >
                                Salvar
                            </Button>
                        </>
                    )}
                </>
            </Stack>
        </>
    );
}

function Form({ data, mutate }) {
    const { setErrors, enqueueSnackbar } = useErrors();
    const navigation = useNavigate();
    const { open, handleClose } = useDialog('#venda');
    const [activeTab, setActiveTab] = useState('termo');
    const { values, handleSubmit, getFieldProps, errors, isSubmitting } = useFormik({
        initialValues: {
            document: '',
            tradeName: '',
            contractorName: '',
            contractorPhone: '',
            contractorPosition: '',
            contractorEmail: '',
            planId: '',
        },

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post(`subscription`, values);
                console.log(data);
                enqueueSnackbar(`Proposta criada com sucesso`);
                await mutate();
                setSubmitting(false);
                navigation(`#venda|${data.data.uuid}`, { replace: true });

            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    return (
        <TabContext value={activeTab}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <DialogTitle>Criar/Editar Proposta</DialogTitle>
                    {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                        <Help />
                    </IconButton> */}
                    <IconButton size="small" onClick={handleClose} sx={{ marginRight: 2 }}>
                        <Close />
                    </IconButton>
                </Stack>
                <Divider />
                {data?.id && (
                    <>
                        <TabList onChange={(_, value) => setActiveTab(value)} variant="scrollable" scrollButtons="auto">
                            <Tab label="Termo de adesão" value="termo" />
                            <Tab label="Assinatura do contrato" value="assinatura" />
                            <Tab label="Plano e mensalidade" value="1" />
                            <Tab label="Customização e adicionais" value="2" />
                        </TabList>
                        <Divider />
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2 }}>
                            <ShareRounded />{' '}
                            <Typography fontSize={14} variant="bolded">
                                https://admclube.net/assinatura/{data?.uuid}
                            </Typography>
                        </Stack>
                        <Divider />
                    </>
                )}
                <DialogContent>
                    {!data?.id && (
                        <Stack spacing={1}>
                            <TextField
                                label="Nome do contato"
                                {...getFieldProps('contractorName')}
                                fullWidth
                                error={!!errors.contractorName}
                                helperText={errors.contractorName}
                            />
                            <TextField
                                label="CNPJ"
                                {...getFieldProps('document')}
                                fullWidth
                                InputProps={{
                                    inputComponent: DocumentInputMasked,
                                }}
                                error={!!errors.document}
                                helperText={errors.document}
                            />
                            <TextField
                                label="Email"
                                {...getFieldProps('contractorEmail')}
                                fullWidth
                                error={!!errors.contractorEmail}
                                helperText={errors.contractorEmail}
                            />
                            <TextField
                                label="Cargo"
                                {...getFieldProps('contractorPosition')}
                                fullWidth
                                error={!!errors.contractorPosition}
                                helperText={errors.contractorPosition}
                            />
                            <TextField
                                label="Telefone"
                                {...getFieldProps('contractorPhone')}
                                fullWidth
                                error={!!errors.contractorPhone}
                                helperText={errors.contractorPhone}
                                InputProps={{
                                    inputComponent: PhoneInputMasked,
                                }}
                            />
                            <SelectPlan {...getFieldProps('planId')} fullWidth error={!!errors.planId} helperText={errors.planId} />
                        </Stack>
                    )}
                    {data?.id && (
                        <>
                            <TabPanel value="termo" sx={{ p: 0 }}>
                                <Termo order={data} />
                            </TabPanel>
                            <TabPanel value="assinatura" sx={{ p: 0 }}>
                                <Contract order={data} mutate={mutate} />
                            </TabPanel>
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <Pagamento order={data} mutate={mutate} />
                            </TabPanel>
                            <TabPanel value="2" sx={{ p: 0 }}>
                                {data?.Suborders.map(order => (
                                    <Pagamento order={order} title="Customização e adicionais" mutate={mutate} />
                                ))}
                            </TabPanel>
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    {Boolean(values.id) && (
                        <Stack sx={{ marginRight: 'auto' }}>
                            <Typography fontSize={12}>Desde: {new Date(values.createdAt).toLocaleString('pt-BR')}</Typography>
                            {values.contractExpiredAt && (
                                <Typography fontSize={12}>
                                    Contrato expira em: {new Date(values.contractExpiredAt).toLocaleString('pt-BR')}
                                </Typography>
                            )}
                        </Stack>
                    )}
                    {!data?.id && (
                        <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} loading={isSubmitting}>
                            Salvar e continuar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </TabContext>
    );
}

function DialogComponent({ mutate }) {
    const { open, id } = useDialog('#venda');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}

export default function Sales() {
    const { data, mutate, error } = useFetch(`subscription`);

    return (
        <Container>
            <Stack py={3} alignItems="center" justifyContent="space-between" direction="row">
                <Typography variant="h2">Vendas</Typography>
            </Stack>

            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" columnGap={2} p={2}>
                    <Button variant="outlined" component={Link} to="#venda">
                        Criar nova venda
                    </Button>
                </Stack>
                <Divider />
                <List variant="table">
                    {data?.map(item => (
                        <React.Fragment>
                            <ListItem>
                                <ListItemButton component={Link} to={`#venda|${item.uuid}`}>
                                    <ListItemText
                                        primary={(item?.Contract && item?.Contract[0]?.contractData?.companyName) || 'Razão social não informada'}
                                        secondary={
                                            <Stack direction="column">
                                                {(item?.Contract && item?.Contract[0]?.contractData?.document) || 'Documento não informado'}
                                                <Typography variant="caption">{new Date(item.createdAt).toLocaleString('pt-BR')}</Typography>
                                            </Stack>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Card>

            <DialogComponent mutate={mutate} />
        </Container>
    );
}
