import { CardContent, Divider, FormControl, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import useErrors from '../../hooks/useErrors';
import server from '../../services/server';
import ClubAuth from '../Club/ClubAuth';

function AdminLogin() {
    const navigation = useNavigate();
    const { setErrors } = useErrors();

    const { values, handleSubmit, handleChange } = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: async values => {
            try {
                const { data } = await server.post('/auth', values);
                navigation(`/auth/?token=${data.data.token}&refreshtoken=${data.data.refreshToken}`, { replace: true });
            } catch (error) {
                setErrors(error);
            }
        },
    });

    return (
        <Stack component="form" alignItems="center" justifyContent="center" onSubmit={handleSubmit}>
            <Box width={300}>
                <Box component="img" src={Logo} alt="Logo" sx={{ width: 180, marginBottom: 4 }} />
                <Stack gap={2}>
                    <TextField 
                    variant="outlined" 
                    fullWidth 
                    value={values.username} 
                    label="Usuário" 
                    onChange={handleChange} 
                    name="username" 
                    InputLabelProps={{
                        shrink: true/false,
                      }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={values.password}
                        type="password"
                        label="Senha"
                        onChange={handleChange}
                        name="password"
                        InputLabelProps={{
                            shrink: true/false,
                          }}
                    />
                    <Button fullWidth type="submit" variant="contained" sx={{ my: 2 }}>
                        Entrar
                    </Button>
                </Stack>
            </Box>
        </Stack>
    );
}

export default function Auth() {
    const [selectedRole, setSelectedRole] = useState(0); // Estado para controlar a seleção da Tab

    const handleTabChange = (event, newValue) => {
        setSelectedRole(newValue); // Atualiza o estado com a nova seleção
    };

    return (
        <Stack width="100vw" height="100vh" backgroundColor="#eee" alignItems="center" justifyContent="center">
            <Card sx={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
                <FormControl
                    sx={{
                        width: '100%',
                        marginBottom: 2,
                        textAlign: 'center',
                    }}
                >
                    <Tabs
                        value={selectedRole}
                        onChange={handleTabChange}
                        centered
                        indicatorColor="primary"
                        textColor="primary"
                        sx={{
                            width: '100%', // Expande Tabs para ocupar toda a largura
                        }}
                    >
                        <Tab label="Administrativo" sx={{ flex: 1 }} /> 
                        <Tab label="Gestor de Clube" sx={{ flex: 1 }} />
                        {/* <Tab label="Anunciante" sx={{ flex: 1 }} /> */}
                    </Tabs>
                    <Divider />
                </FormControl>

                <CardContent>
                    {/* Renderiza o componente de acordo com a seleção */}
                    {selectedRole === 0 && <AdminLogin />} {/* 0: Administrativo */}
                    {selectedRole === 1 && <ClubAuth />} {/* 1: Gestor de Clube */}
                </CardContent>
            </Card>
        </Stack>
    );
}