import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import Logo from './../assets/logo.png';
import { useFormik } from 'formik';
import DocumentInputMasked from '../components/DocumentInputMasked';
import Address from '../components/Address';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../hooks/useFecth';
import React, { useEffect } from 'react';
import server from '../services/server';
import { LoadingContent } from '../components/Loading';
import useErrors from '../hooks/useErrors';
import PhoneInputMasked from '../components/PhoneInputMasked';
import { payment_status } from '../services/helpers';
import { PriceInputMasked } from '../components/PriceMask';
import ZipCodeInputMasked from '../components/ZipCodeInputMasked';
import CreditCardInputMasked from '../components/CreditCardInputMasked';
import { AssignmentTurnedIn, AutoAwesome, AutoAwesomeRounded, DocumentScannerRounded, DrawRounded, RemoveRedEyeRounded } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';

const schema = Yup.object().shape({
    document: Yup.string().required('Documento é obrigatório'),
    companyName: Yup.string().required('Razão Social é obrigatório'),
    tradeName: Yup.string().required('Nome Fantasia é obrigatório'),
    stateRegistration: Yup.string().required('Inscrição Estadual é obrigatório'),
    zipcode: Yup.string().required('CEP é obrigatório'),
    address: Yup.string().required('Endereço é obrigatório'),
    number: Yup.string().required('Número é obrigatório'),
    complement: Yup.string().required('Complemento é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),

    contractorName: Yup.string().required('Nome do Contratante é obrigatório'),
    contractorName: Yup.string().required('Nome do Contratante é obrigatório'),
    contractorDocument: Yup.string().required('Documento do Contratante é obrigatório'),
    // contractorNationality: Yup.string().required('Nacionalidade do Contratante é obrigatório'),
    contractorPhone: Yup.string().required('Telefone do Contratante é obrigatório'),
    // contractorBirthDate: Yup.string().required('Data de Nascimento do Contratante é obrigatório'),
    // contractorGender: Yup.string().required('Gênero do Contratante é obrigatório'),
    contractorPosition: Yup.string().required('Cargo do Contratante é obrigatório'),
    contractorEmail: Yup.string().required('Email do Contratante é obrigatório'),
    ///email: Yup.string().required('Email é obrigatório'),
    //position: Yup.string().required('Cargo é obrigatório'),
    //phone: Yup.string().required('Telefone é obrigatório'),
    //mobile: Yup.string().required('Celular é obrigatório'),
    // value: Yup.string().required('Mensalidade é obrigatório'),
    // planId: Yup.string().required('Plano é obrigatório'),
    // paymentMethod: Yup.string().required('Forma de Pagamento é obrigatório'),
    // paymentDay: Yup.string().required('Dia de Pagamento é obrigatório'),
});

function Termo({ mutate, order }) {
    const { propostaId } = useParams();
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, getFieldProps, setFieldValue, setValues, isSubmitting, errors, handleSubmit, isValid } = useFormik({
        initialValues: 
        
        // {
        //     document: '12331134000174',
        //     companyName: 'Empresa Teste LTDA',
        //     tradeName: 'Empresa Teste',
        //     stateRegistration: '123456789',
        //     isExempt: false,
        //     isSimpleOptant: false,
        //     address: 'Rua de Teste',
        //     number: '123',
        //     complement: 'Sala 1',
        //     neighborhood: 'Bairro Teste',
        //     city: 'Cidade Teste',
        //     state: 'SP',
        //     zipcode: '01000000',

        //     contractorName: 'Contratante Teste',
        //     contractorDocument: '12345678900',
        //     contractorNationality: 'Brasileira',
        //     contractorPhone: '11999999999',
        //     contractorBirthDate: '01/01/1990',
        //     contractorGender: 'Masculino',
        //     contractorPosition: 'Gerente',
        //     contractorEmail: 'teste@teste.com',
        //     domain: 'teste.com',

        //     paymentMethod: 'CREDIT_CARD',
        //     paymentDay: '10',
        // },
        {
            document: '',
            companyName: '',
            tradeName: '',
            stateRegistration: '',
            isExempt: false,
            isSimpleOptant: false,
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            zipcode: '',

            contractorName: '',
            contractorDocument: '',
            contractorNationality: '',
            contractorPhone: '',
            contractorBirthDate: '',
            contractorGender: '',
            contractorPosition: '',
            contractorEmail: '',
            domain: '',

            paymentMethod: '',
            paymentDay: '',
        },
        validationSchema: schema,
        validateOnChange: true,
        validateOnMount: true,

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`subscription/${propostaId}/term`, {
                    contractData: values,
                });
                await mutate();
                setSubmitting(false);
                enqueueSnackbar('Dados gravados.');
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (Boolean(order?.Contract?.contractData)) {
            setValues({ ...order?.Contract?.contractData }, true);
        }
    }, [order]);

    const hasContract = Boolean(order?.orderData?.contractId);
    const hasTerm = Boolean(order?.orderData?.contract);
    const hasSignature = Boolean(order?.Contract?.signedAt);

    return (
        <>
            <Paper variant="outlined" sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="h3" component="h2">
                        Termo de aceite
                    </Typography>
                    {!hasContract && (
                        <>
                            <Divider variant="fullWidth" />
                            <Typography variant="body1">
                                Bem-vindo à Eurobem Benefícios! Este é um termo de aceite para adesão ao clube de benefícios, gerido pela Eurobem
                                Benefícios, CNPJ: 12.345.678/0001-90, com sede na Rua Exemplo, 123, Bairro Centro, São Paulo - SP, CEP: 01000-000.
                                Para dúvidas ou suporte, entre em contato pelo email: contato@eurobem.com.br.
                            </Typography>
                            <Typography variant="body1">
                                Após a geração deste contrato, ele deverá ser assinado para formalizar sua adesão. O clube será efetivamente criado
                                após a terceira etapa, que consiste na confirmação do pagamento.
                            </Typography>
                            <Divider>Dados jurídicos</Divider>
                            <TextField
                                label="CNPJ"
                                {...getFieldProps('document')}
                                fullWidth
                                InputProps={{
                                    inputComponent: DocumentInputMasked,
                                }}
                                error={!!errors.document}
                                helperText={errors.document}
                            />

                            <TextField
                                label="Razão Social"
                                {...getFieldProps('companyName')}
                                fullWidth
                                error={!!errors.companyName}
                                helperText={errors.companyName}
                            />
                            <TextField
                                label="Nome Fantasia"
                                {...getFieldProps('tradeName')}
                                fullWidth
                                error={!!errors.tradeName}
                                helperText={errors.tradeName}
                            />
                            <TextField
                                label="Inscrição Estadual"
                                {...getFieldProps('stateRegistration')}
                                fullWidth
                                error={!!errors.stateRegistration}
                                helperText={errors.stateRegistration}
                            />
                            <Address
                                {...getFieldProps('zipcode')}
                                setFieldValue={setFieldValue}
                                address={values?.address}
                                error={!!errors.zipcode}
                                helperText={errors.zipcode}
                            />
                            <Stack direction="row" columnGap={2}>
                                <TextField
                                    fullWidth
                                    label="Complemento"
                                    {...getFieldProps('complement')}
                                    error={!!errors.complement}
                                    helperText={errors.complement}
                                />
                                <TextField
                                    sx={{ width: 150 }}
                                    label="Número"
                                    {...getFieldProps('number')}
                                    error={!!errors.number}
                                    helperText={errors.number}
                                />
                            </Stack>

                            <Divider>Dados do responsável contratante</Divider>
                            <TextField
                                label="Nome do Contratante"
                                {...getFieldProps('contractorName')}
                                fullWidth
                                error={!!errors.contractorName}
                                helperText={errors.contractorName}
                            />
                            <TextField
                                label="Email"
                                {...getFieldProps('contractorEmail')}
                                fullWidth
                                error={!!errors.contractorEmail}
                                helperText={errors.contractorEmail}
                            />
                            <TextField
                                label="Cargo"
                                {...getFieldProps('contractorPosition')}
                                fullWidth
                                error={!!errors.contractorPosition}
                                helperText={errors.contractorPosition}
                            />
                            <TextField
                                label="Telefone"
                                {...getFieldProps('contractorPhone')}
                                fullWidth
                                error={!!errors.contractorPhone}
                                helperText={errors.contractorPhone}
                                InputProps={{
                                    inputComponent: PhoneInputMasked,
                                }}
                            />
                            {/* <TextField
                                label="Celular"
                                {...getFieldProps('mobile')}
                                fullWidth
                                error={!!errors.mobile}
                                helperText={errors.mobile}
                                InputProps={{
                                    inputComponent: PhoneInputMasked,
                                }}
                            /> */}

                            <Divider>Plano e mensalidade</Divider>
                            {order?.Order_products?.map(item => (
                                <Stack direction="row" spacing={2} key={item.id}>
                                    <TextField
                                        label="Nome"
                                        inputProps={{ readyOnly: true }}
                                        fullWidth
                                        value={item.Product.name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Valor"
                                        fullWidth
                                        value={item.Product.price}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>
                            ))}

                            <Stack direction="row" spacing={2}>
                                <TextField
                                    label="Valor da Mensalidade"
                                    fullWidth
                                    value={order?.subtotal || 0}
                                    InputProps={{
                                        readOnly: true,
                                        inputComponent: PriceInputMasked,
                                    }}
                                />
                            </Stack>
                            {Boolean(order?.discount) && (
                                <>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            label="Aplicar desconto"
                                            fullWidth
                                            value={order?.discount}
                                            InputProps={{
                                                readOnly: true,
                                                inputComponent: PriceInputMasked,
                                            }}
                                        />
                                    </Stack>

                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            label="Valor a faturar"
                                            fullWidth
                                            value={order?.total}
                                            InputProps={{
                                                readOnly: true,
                                                inputComponent: PriceInputMasked,
                                            }}
                                        />
                                    </Stack>
                                </>
                            )}
                            {Boolean(order?.Suborders.length) && <Divider>Customizações e outros serviços</Divider>}
                            {order?.Suborders?.map(subOrder => (
                                <React.Fragment key={subOrder.id}>
                                    {subOrder?.Order_products?.map(item => (
                                        <React.Fragment>
                                            <Stack direction="row" spacing={2} key={item.id}>
                                                <TextField
                                                    label="Nome"
                                                    inputProps={{ readyOnly: true }}
                                                    fullWidth
                                                    value={item.Product.name}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                                <TextField
                                                    label="Valor"
                                                    fullWidth
                                                    value={item.Product.price}
                                                    InputProps={{
                                                        readOnly: true,
                                                        inputComponent: PriceInputMasked,
                                                    }}
                                                />
                                            </Stack>
                                        </React.Fragment>
                                    ))}
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            label="Total da Mensalidade"
                                            fullWidth
                                            value={subOrder?.total || 0}
                                            InputProps={{
                                                readOnly: true,
                                                inputComponent: PriceInputMasked,
                                            }}
                                        />
                                    </Stack>
                                    {Boolean(subOrder?.discount) && (
                                        <>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    label="Desconto"
                                                    fullWidth
                                                    value={subOrder?.discount || 0}
                                                    InputProps={{
                                                        readOnly: true,
                                                        inputComponent: PriceInputMasked,
                                                    }}
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    label="Valor total"
                                                    fullWidth
                                                    value={subOrder?.total || 0}
                                                    InputProps={{
                                                        readOnly: true,
                                                        inputComponent: PriceInputMasked,
                                                    }}
                                                />
                                            </Stack>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                            {/* <TextField label="Dia de Pagamento" {...getFieldProps('paymentDay')} fullWidth error={!!errors.paymentDay} helperText={errors.paymentDay}/> */}
                            <Divider />
                            <Button
                                variant="contained"
                                sx={{ alignSelf: 'flex-end' }}
                                disabled={!isValid || isSubmitting || hasSignature}
                                onClick={handleSubmit}
                            >
                                {!hasTerm ? 'Confirmar e Gerar contrato' : 'Corrigir dados do contrato'}
                            </Button>
                        </>
                    )}
                    {hasContract && (
                        <>
                            <Divider />
                            <Alert severity="success">Contrato gerado com sucesso</Alert>
                        </>
                    )}
                </Stack>
            </Paper>
            <Contract order={order} mutate={mutate} />
        </>
    );
}

function Contract({ mutate, order }) {
    const { propostaId } = useParams();
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();
    const { values, getFieldProps, setFieldValue, setValues, isSubmitting, errors, handleSubmit, isValid } = useFormik({
        initialValues: {
            paymentDay: '10',
        },
        validateOnChange: true,
        validateOnMount: true,

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await confirm({
                    title: 'Assinatura do contrato',
                    description: `Você está gerando o contrato para assinatura digital. Os dados serão enviados para ${order.orderData.contract.contractorEmail}`,
                    confirmationText: 'Confirmo',
                    contentProps: { dividers: true },
                });
                await server.put(`subscription/${propostaId}/contract`);
                await mutate();
                setSubmitting(false);
                enqueueSnackbar('Contrato gerado.');
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    // useEffect(() => {
    //     if (Boolean(order?.Contract?.contractData)) {
    //         setValues({ ...order?.Contract?.contractData }, true);
    //     }
    // }, [order]);

    const hasContract = Boolean(order?.orderData?.contractId);
    const hasTerm = Boolean(order?.orderData?.contract);
    const hasSignature = Boolean(order?.Contract?.signedAt);

    return (
        <>
            <Paper variant="outlined" sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="h3" component="h2">
                        Assinatura do contrato
                    </Typography>
                    {hasTerm && !hasContract && (
                        <>
                            <Divider />
                            <Typography variant="body1">
                                Você preencheu a proposta com os dados solicitados. Agora vamos parar a etapa onde você precisa gerar o contrato para
                                assinatura para assinatura digital.
                            </Typography>
                            <Card>
                                <CardContent>
                                    <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
                                        <DrawRounded fontSize="large" sx={{ fontSize: 70 }} color="action" />
                                        <Divider orientation="vertical" flexItem />
                                        <Typography variant="body1" textAlign="center">
                                            O contrato será enviado para <strong>{order.orderData.contract.contractorEmail}</strong>
                                        </Typography>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack direction="row" sx={{ width: 200 }} justifyContent="center">
                                            <Button
                                                sx={{ alignSelf: 'flex-end' }}
                                                variant="outlined"
                                                disabled={isSubmitting || hasSignature}
                                                onClick={handleSubmit}
                                            >
                                                Assinar contrato
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Divider />
                        </>
                    )}
                    {hasContract && !hasSignature && (
                        <>
                            <Divider />
                            <Typography variant="body1">
                                O contrato foi enviado para o email <strong>{order.orderData.contract.contractorEmail}</strong>. Ao assinar o contrato o sistema será atualizado automaticamente.
                            </Typography>
                            <Alert severity="info">Aguardando a assinatura do contrato</Alert>
                        </>
                    )}
                    {hasContract && hasSignature && (
                        <>
                            <Divider />
                            <Alert severity="success">Contrato assinado com sucesso</Alert>
                        </>
                    )}
                </Stack>
            </Paper>
        </>
    );
}
// const paymentSchema = Yup.object().shape({
//     name: Yup.string().required('Nome é obrigatório'),
//     email: Yup.string().email('Email inválido').required('Email é obrigatório'),
//     cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório'),
//     phone: Yup.string().required('Telefone é obrigatório'),
//     mobilePhone: Yup.string().required('Celular é obrigatório'),
//     postalCode: Yup.string().required('CEP é obrigatório'),
//     address: Yup.string().required('Endereço é obrigatório'),
//     addressNumber: Yup.string().required('Número é obrigatório'),
//     province: Yup.string().required('Bairro é obrigatório'),
//     creditCard: Yup.string().required('Número do cartão é obrigatório'),
//     creditCardHolderName: Yup.string().required('Nome do titular é obrigatório'),
//     creditCardExpiryMonth: Yup.string()
//         .required('Mês de expiração é obrigatório')
//         .matches(/^(0[1-9]|1[0-2])$/, 'Mês inválido'),
//     creditCardExpiryYear: Yup.string()
//         .required('Ano de expiração é obrigatório')
//         .matches(/^(20)\d{2}$/, 'Ano inválido'),
//     creditCardCcv: Yup.string().required('CVV é obrigatório')
// });

const requiredIfCreditCard = message =>
    Yup.string().when('billingType', {
        is: 'CREDIT_CARD',
        then: schema => schema.required(message),
    });

const paymentSchema = Yup.object().shape({
    name: requiredIfCreditCard('Nome é obrigatório'),
    email: Yup.string().when('billingType', {
        is: 'CREDIT_CARD',
        then: schema => schema.email('Email inválido').required('Email é obrigatório'),
    }),
    cpfCnpj: requiredIfCreditCard('CPF/CNPJ é obrigatório'),
    phone: requiredIfCreditCard('Telefone é obrigatório'),
    mobilePhone: requiredIfCreditCard('Celular é obrigatório'),
    postalCode: requiredIfCreditCard('CEP é obrigatório'),
    address: requiredIfCreditCard('Endereço é obrigatório'),
    addressNumber: requiredIfCreditCard('Número é obrigatório'),
    province: requiredIfCreditCard('Bairro é obrigatório'),
    creditCard: requiredIfCreditCard('Número do cartão é obrigatório'),
    creditCardHolderName: requiredIfCreditCard('Nome do titular é obrigatório'),

    creditCardExpiryMonth: Yup.string().when('billingType', {
        is: 'CREDIT_CARD',
        then: schema => schema.required('Mês de expiração é obrigatório').matches(/^(0[1-9]|1[0-2])$/, 'Mês inválido'),
    }),

    creditCardExpiryYear: Yup.string().when('billingType', {
        is: 'CREDIT_CARD',
        then: schema =>
            schema
                .required('Ano de expiração é obrigatório')
                .matches(/^(20)\d{2}$/, 'Ano inválido')
                .test('valid-year', 'Ano inválido', value => {
                    const year = Number(value);
                    return year >= new Date().getFullYear(); // Garante que o ano é futuro ou presente
                }),
    }),

    creditCardCcv: Yup.string().when('billingType', {
        is: 'CREDIT_CARD',
        then: schema => schema.required('CVV é obrigatório').matches(/^\d{3,4}$/, 'CVV inválido'),
    }),
    installmentCount: Yup.string(),
});

function Pagamento({ mutate, order, title, subOrder }) {
    const { propostaId } = useParams();
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, getFieldProps, setFieldValue, isSubmitting, errors, handleSubmit, isValid } = useFormik({
        initialValues: {
            name: 'João Silva',
            email: 'joao.silva@example.com',
            cpfCnpj: '123.456.789-09',
            phone: '(11) 98765-4321',
            mobilePhone: '(11) 91234-5678',
            postalCode: '01001-000',
            address: 'Rua das Flores, 123',
            addressNumber: '456',
            addressComplement: 'Apto 12B',
            province: 'Centro',
            creditCard: '4111 1111 1111 1111',
            creditCardHolderName: 'João Silva',
            creditCardExpiryMonth: '08',
            creditCardExpiryYear: '2027',
            creditCardCcv: '123',
            billingType: 'CREDIT_CARD',
            subOrderId: 'abc123xyz',
            installmentCount: '1',
            subOrderId: subOrder?.id || '',
        },
        // {
        //     name: '',
        //     email: '',
        //     cpfCnpj: '',
        //     phone: '',
        //     mobilePhone: '',
        //     postalCode: '',
        //     address: '',
        //     addressNumber: '',
        //     addressComplement: '',
        //     province: '',
        //     creditCard: '',
        //     creditCardHolderName: '',
        //     creditCardExpiryMonth: '',
        //     creditCardExpiryYear: '',
        //     creditCardCcv: '',
        //     billingType: 'CREDIT_CARD',
        //     subOrderId: subOrder?.id || '',
        //     installmentCount: '1',
        // },
        validationSchema: paymentSchema,
        validateOnChange: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`subscription/${propostaId}/payment`, values);
                await mutate();
                setSubmitting(false);
                enqueueSnackbar('Pagamento gerado');
            } catch (error) {
                setErrors(error);
            }
        },
    });

    const isPrincipalOrder = !subOrder ? Boolean(order?.childOf) : Boolean(subOrder?.childOf);
    const hasSignature = Boolean(order?.Contract?.signedAt);
    const hasPayment = !subOrder ? !['WAITING'].includes(order?.paymentStatus) : !['WAITING'].includes(subOrder?.paymentStatus);
    const waitingPayment = !subOrder ? ['PENDING'].includes(order?.paymentStatus) : ['PENDING'].includes(subOrder?.paymentStatus);

    // !subOrder
    //     ? Boolean(order?.paymentStatus) && Boolean(order?.paymentId)
    //     : Boolean(subOrder?.paymentStatus) && Boolean(subOrder?.paymentId);

    const paid = !subOrder ? ['CONFIRMED', 'RECEIVED'].includes(order?.paymentStatus) : ['CONFIRMED', 'RECEIVED'].includes(subOrder?.paymentStatus);
    const notPaid = !subOrder
        ? ['WAITING', 'REPROVED_BY_RISK_ANALYSIS', 'AWAITING_RISK_ANALYSIS', 'ATTEMP', 'CREDIT_CARD_CAPTURE_REFUSED'].includes(order?.paymentStatus)
        : ['WAITING', 'REPROVED_BY_RISK_ANALYSIS', 'AWAITING_RISK_ANALYSIS', 'ATTEMP', 'CREDIT_CARD_CAPTURE_REFUSED'].includes(
              subOrder?.paymentStatus,
          );
    const orderStatus = !subOrder ? order?.paymentStatus : subOrder?.paymentStatus;
    const paymentData = !subOrder ? order?.paymentData : subOrder?.paymentData;

    const orderProducts = !subOrder ? order?.Order_products : subOrder?.Order_products;
    const orderSubtotal = !subOrder ? order?.subtotal : subOrder?.subtotal;
    const orderTotal = !subOrder ? order?.total : subOrder?.total;
    const orderDiscount = !subOrder ? order?.discount : subOrder?.discount;

    if (Boolean(subOrder)) {
        if (!['CONFIRMED', 'RECEIVED'].includes(order?.paymentStatus)) {
            return (
                <Paper variant="outlined" sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h2">
                            {title || 'Pagamento da adesão'}
                        </Typography>
                    </Stack>
                </Paper>
            );
        }
    }

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant="h3" component="h2">
                    {title || 'Pagamento da adesão'}
                </Typography>
                {hasSignature && (
                    <>
                        <Divider />
                        <div>
                            {Boolean(subOrder) ? (
                                <Typography variant="body1">Para seguir com a proposta conclua o pagamento</Typography>
                            ) : (
                                <Typography variant="body1">Para seguir com a proposta conclua o pagamento da adesão</Typography>
                            )}

                            <Typography variant="body2">* A cobrança será faturada em nome da empresa contrante que contra no contrato.</Typography>
                        </div>
                        {orderProducts?.map(item => (
                            <Stack direction="row" spacing={2} key={item.id}>
                                <TextField
                                    label="Nome"
                                    inputProps={{ readyOnly: true }}
                                    fullWidth
                                    value={item.Product.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Valor"
                                    fullWidth
                                    value={Number(item.Product.price)}
                                    InputProps={{
                                        readOnly: true,
                                        inputComponent: PriceInputMasked,
                                    }}
                                />
                            </Stack>
                        ))}
                        <TextField
                            value={Number(orderSubtotal)}
                            label="Total"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                                inputComponent: PriceInputMasked,
                            }}
                        />
                        {Boolean(orderDiscount) && (
                            <>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="Desconto aplicado"
                                        fullWidth
                                        value={orderDiscount}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="Valor com desconto"
                                        fullWidth
                                        value={orderTotal}
                                        InputProps={{
                                            readOnly: true,
                                            inputComponent: PriceInputMasked,
                                        }}
                                    />
                                </Stack>
                            </>
                        )}

                        {!hasPayment && (
                            <>
                                <ToggleButtonGroup
                                    exclusive
                                    onChange={event => setFieldValue('billingType', event.target.value)}
                                    fullWidth
                                    value={values.billingType}
                                >
                                    <ToggleButton value="CREDIT_CARD">Cartão de Crédito</ToggleButton>
                                    <ToggleButton value="BOLETO">Boleto</ToggleButton>
                                    <ToggleButton value="PIX">PIX</ToggleButton>
                                </ToggleButtonGroup>

                                <>
                                    {values.billingType === 'CREDIT_CARD' && (
                                        <Stack spacing={1}>
                                            <Divider sx={{ py: 4 }}>Dados do portador do cartão</Divider>
                                            <Stack direction="row" columnGap={2}>
                                                <TextField
                                                    label="CPF/CNPJ"
                                                    {...getFieldProps('cpfCnpj')}
                                                    fullWidth
                                                    error={!!errors.cpfCnpj}
                                                    helperText={errors.cpfCnpj}
                                                    InputProps={{
                                                        inputComponent: DocumentInputMasked,
                                                    }}
                                                />
                                                <TextField
                                                    label="Email"
                                                    {...getFieldProps('email')}
                                                    fullWidth
                                                    error={!!errors.email}
                                                    helperText={errors.email}
                                                />
                                            </Stack>
                                            <TextField
                                                label="Nome"
                                                {...getFieldProps('name')}
                                                fullWidth
                                                error={!!errors.name}
                                                helperText={errors.name}
                                            />
                                            <Stack direction="row" columnGap={1}>
                                                <TextField
                                                    label="Telefone"
                                                    {...getFieldProps('phone')}
                                                    fullWidth
                                                    error={!!errors.phone}
                                                    helperText={errors.phone}
                                                    InputProps={{
                                                        inputComponent: PhoneInputMasked,
                                                    }}
                                                />
                                                <TextField
                                                    label="Celular"
                                                    {...getFieldProps('mobilePhone')}
                                                    fullWidth
                                                    error={!!errors.mobilePhone}
                                                    helperText={errors.mobilePhone}
                                                    InputProps={{
                                                        inputComponent: PhoneInputMasked,
                                                    }}
                                                />
                                            </Stack>
                                            <Stack direction="row" columnGap={2}>
                                                <TextField
                                                    label="CEP"
                                                    {...getFieldProps('postalCode')}
                                                    fullWidth
                                                    error={!!errors.postalCode}
                                                    helperText={errors.postalCode}
                                                    InputProps={{
                                                        inputComponent: ZipCodeInputMasked,
                                                    }}
                                                />
                                                <TextField
                                                    label="Bairro"
                                                    {...getFieldProps('province')}
                                                    fullWidth
                                                    error={!!errors.province}
                                                    helperText={errors.province}
                                                />
                                            </Stack>
                                            <Stack direction="row" columnGap={2}>
                                                <TextField
                                                    label="Endereço"
                                                    {...getFieldProps('address')}
                                                    fullWidth
                                                    error={!!errors.address}
                                                    helperText={errors.address}
                                                />
                                                <TextField
                                                    sx={{ width: 200 }}
                                                    label="Número"
                                                    {...getFieldProps('addressNumber')}
                                                    fullWidth
                                                    error={!!errors.addressNumber}
                                                    helperText={errors.addressNumber}
                                                    type="number"
                                                />
                                            </Stack>
                                            <TextField label="Complemento" {...getFieldProps('addressComplement')} fullWidth />

                                            <Divider sx={{ py: 4 }}>Dados do cartão</Divider>

                                            <TextField
                                                label="Número do Cartão"
                                                {...getFieldProps('creditCard')}
                                                fullWidth
                                                error={!!errors.creditCard}
                                                helperText={errors.creditCard}
                                                //type='number'
                                                InputProps={{
                                                    inputComponent: CreditCardInputMasked,
                                                }}
                                            />
                                            <TextField
                                                label="Nome do Titular"
                                                {...getFieldProps('creditCardHolderName')}
                                                fullWidth
                                                error={!!errors.creditCardHolderName}
                                                helperText={errors.creditCardHolderName}
                                            />
                                            <Stack direction="row" columnGap={2}>
                                                <TextField
                                                    label="Mês de Expiração"
                                                    {...getFieldProps('creditCardExpiryMonth')}
                                                    fullWidth
                                                    error={!!errors.creditCardExpiryMonth}
                                                    helperText={errors.creditCardExpiryMonth}
                                                    type="number"
                                                />
                                                <TextField
                                                    label="Ano de Expiração"
                                                    {...getFieldProps('creditCardExpiryYear')}
                                                    fullWidth
                                                    error={!!errors.creditCardExpiryYear}
                                                    helperText={errors.creditCardExpiryYear}
                                                    type="number"
                                                />
                                                <TextField
                                                    label="CVV"
                                                    {...getFieldProps('creditCardCcv')}
                                                    fullWidth
                                                    error={!!errors.creditCardCcv}
                                                    helperText={errors.creditCardCcv}
                                                    type="number"
                                                />
                                            </Stack>
                                            {Boolean(subOrder) && (
                                                <>
                                                    <FormControl fullWidth error={!!errors.installmentCount}>
                                                        <InputLabel size="small" id="payment-method-installments">
                                                            Parcelamento
                                                        </InputLabel>
                                                        <Select
                                                            labelId="payment-method-installments"
                                                            label="Parcelamento"
                                                            {...getFieldProps('installmentCount')}
                                                        >
                                                            <MenuItem value="1">1 parcela</MenuItem>
                                                            <MenuItem value="2">2 parcelas</MenuItem>
                                                            <MenuItem value="3">3 parcelas</MenuItem>
                                                            <MenuItem value="4">4 parcelas</MenuItem>
                                                            <MenuItem value="5">5 parcelas</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}

                                            <Divider />
                                            <Button
                                                onClick={handleSubmit}
                                                disabled={!isValid || isSubmitting}
                                                variant="contained"
                                                sx={{ alignSelf: 'flex-end' }}
                                            >
                                                Processar pagamento
                                            </Button>
                                        </Stack>
                                    )}

                                    {(values.billingType === 'BOLETO' || values.billingType === 'PIX') && (
                                        <>
                                            <Divider />
                                            <Button
                                                onClick={handleSubmit}
                                                disabled={!isValid || isSubmitting}
                                                variant="contained"
                                                sx={{ alignSelf: 'flex-end' }}
                                            >
                                                Gerar pagamento
                                            </Button>
                                        </>
                                    )}
                                </>
                            </>
                        )}

                        {waitingPayment && (
                            <>
                                <Divider />
                                {/* <Alert severity="warning">Aguardando pagamento</Alert> */}

                                {paymentData && paymentData.billingType === 'BOLETO' && (
                                    <Stack direction="row" spacing={2}>
                                        <Card>
                                            <CardContent>
                                                <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
                                                    <DocumentScannerRounded fontSize="large" sx={{ fontSize: 70 }} color="action" />
                                                    <Divider orientation="vertical" flexItem />
                                                    <Typography variant="body1" textAlign="center">
                                                        Seu boleto vence em ate 5 dias. Faça o pagamento para concluir a proposta.
                                                    </Typography>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Stack direction="row" sx={{ width: 200 }} justifyContent="center">
                                                        <Button variant="outlined" color="success" href={paymentData?.bankSlipUrl} target="_blank">
                                                            Ver Boleto
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                )}

                                {paymentData && paymentData.billingType === 'PIX' && (
                                    <Stack spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            src={`data:image/png;base64,${paymentData?.chargePix?.encodedImage}`}
                                            alt="QrCode"
                                            width={200}
                                            height={200}
                                            border={1}
                                            borderColor="text.secondary"
                                            borderRadius={1}
                                        />
                                        <TextField
                                            fullWidth
                                            value={paymentData?.chargePix?.payload}
                                            label="Chave Pix"
                                            InputProps={{ readOnly: 'readonly' }}
                                        />
                                        <Button variant="outlined" target="_blank">
                                            Copiar codigo
                                        </Button>
                                    </Stack>
                                )}
                            </>
                        )}

                        <Divider />
                        {paid && <Alert severity="success">Pagamento realizado com sucesso</Alert>}
                        {notPaid && <Alert severity="info">{payment_status[orderStatus]}</Alert>}
                    </>
                )}
            </Stack>
        </Paper>
    );
}

const schemaClub = Yup.object().shape({
    name: Yup.string().required('Nome do clube é obrigatório'),
    password: Yup.string().required('Senha é obrigatória').min(8, 'A senha deve ter no mínimo 6 caracteres'),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
        .required('Repetir senha é obrigatório'),
    ownerName: Yup.string().required('Nome do responsável é obrigatório'),
    ownerEmail: Yup.string().email('Email inválido').required('Email do responsável é obrigatório'),
    ownerDocument: Yup.string().required('Documento do responsável é obrigatório'),
});
function CriarClube({ mutate, order }) {
    const { setErrors, enqueueSnackbar } = useErrors();
    const { propostaId } = useParams();
    const navigation = useNavigate();

    const { values, handleSubmit, handleChange, errors, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            password: '',
            repeatPassword: '',
            ownerName: '',
            ownerEmail: '',
            ownerDocument: '',
            security: true,
        },
        validationSchema: schemaClub,
        validateOnChange: true,
        validateOnMount: true,

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`subscription/${propostaId}/club`, values);
                await mutate();
                enqueueSnackbar('Clube criado com sucesso');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const hasClub = Boolean(order?.Contract?.clubId) && order?.status === 'CONCLUDED';
    const hasPaid = !['WAITING'].includes(order?.paymentStatus);
    const hasSubordersPaid = !order?.Suborders.map(suborder => ['CONFIRMED', 'RECEIVED'].includes(suborder.paymentStatus)).includes(false);

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant="h3" component="h2">
                    Crie seu clube
                </Typography>

                {!hasClub && hasPaid && hasSubordersPaid && (
                    <>
                        <Divider />
                        <Typography variant="body2">
                            Os dados preenchidos no contrao serão usados para identificar a pessoa jurídica do clube.
                        </Typography>
                        <Divider />
                        <TextField
                            label="Nome do clube"
                            onChange={handleChange}
                            placeholder="Nome do clube"
                            name="name"
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                        />

                        {/* <TextField
                            required
                            label="Telefone para suporte"
                            onChange={event => setFieldValue('supportPhone', event.target?.value || event)}
                            placeholder="Telefone do clube"
                            name="supportPhone"
                            value={values.supportPhone}
                            error={Boolean(errors.supportPhone)} // Exibe o estado de erro
                            helperText={errors.supportPhone} // Mensagem de erro personalizada
                            InputProps={{
                                inputComponent: PhoneInputMasked,
                            }}
                        />
                        <TextField
                            label="Email de suporte"
                            onChange={handleChange}
                            placeholder="E-mail de suporte"
                            name="supportEmail"
                            value={values.supportEmail}
                        /> */}
                        <Divider>Acesso administrativo do clube</Divider>

                        <TextField
                            label="Nome do responsável pelo clube"
                            onChange={handleChange}
                            placeholder="Nome do dono clube"
                            name="ownerName"
                            value={values.ownerName}
                            error={!!errors.ownerName}
                            helperText={errors.ownerName}
                        />

                        <TextField
                            label="Email do responsável pelo clube"
                            onChange={handleChange}
                            placeholder="Email do dono clube"
                            name="ownerEmail"
                            value={values.ownerEmail}
                            error={!!errors.ownerEmail}
                            helperText={errors.ownerEmail}
                        />

                        <Typography variant="body2">
                            Para acessar o painel o adminsitrativo e também o clube, crie abaixo as credenciais de acesso.
                        </Typography>

                        <TextField
                            label="Documento do responsável (Acesso ao clube)"
                            onChange={handleChange}
                            placeholder="Documento do responsável "
                            name="ownerDocument"
                            value={values.ownerDocument}
                            InputProps={{
                                inputComponent: DocumentInputMasked,
                            }}
                            error={!!errors.ownerDocument}
                            helperText={errors.ownerDocument}
                        />

                        <TextField
                            label="Crie uma senha"
                            onChange={handleChange}
                            placeholder="Senha de acesso"
                            name="password"
                            value={values.password}
                            type={values.security ? 'password' : 'text'}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment onClick={() => setFieldValue('security', !values.security)} position="end">
                                        <RemoveRedEyeRounded />{' '}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Repita a senha"
                            onChange={handleChange}
                            placeholder="Repita a senha de acesso"
                            name="repeatPassword"
                            value={values.repeatPassword}
                            type={values.security ? 'password' : 'text'}
                            error={!!errors.repeatPassword}
                            helperText={errors.repeatPassword}
                        />

                        <Button sx={{alignSelf: 'flex-end'}} variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                            Criar seu clube
                        </Button>
                    </>
                )}

                {hasClub && hasPaid && hasSubordersPaid && (
                    <>
                        <Divider />
                        <Stack textAlign="center" alignItems="center" py={3} mx="auto">
                            <AutoAwesomeRounded sx={{ fontSize: 80, color: 'orange' }} />
                            <Typography variant="h3">Parabéns! </Typography>
                            <Divider sx={{width: 200, my:2}} />
                            <Typography variant="body1">Você agora faz parte da Eurobem Clubes de Benefícios</Typography>
                            <Typography variant="bolded">Confira seu e-mail para ter acesso ao <br/> seu clube e começar sua jornada. Até lá!!</Typography>
                        </Stack>
                    </>
                )}
            </Stack>
        </Paper>
    );
}

export default function Order() {
    const { propostaId } = useParams();

    const { data, error, isValidating, mutate } = useFetch(propostaId ? `/subscription/${propostaId}` : null);

    // if (isValidating) return <LoadingContent />;

    // if (!Boolean(propostaId) && !data) return <>Proposta não encontrada</>;

    // if (error) return <>Error ao buscar proposta</>;

    // if (isValidating) return <LoadingContent />;
    return (
        <Container sx={{ maxWidth: { md: 800 }, paddingBottom: 6 }}>
            <Stack rowGap={2}>
                <Box component="img" src={Logo} width={200} height="auto" />
                <Typography variant="h1" component="h1">
                    Proposta de Adesão
                </Typography>
                <Divider />
                <Box>
                    <Typography variant="body2">1. Leia com atenção a proposta comercial e confira se os dados estão de acordo.</Typography>
                    <Typography variant="body2">2. Acesse os termos e condições para uso do software e revise as cláusulas.</Typography>
                    <Typography variant="body2">
                        3. Caso esteja de acordo, realize o preenchimento dos campos abaixo, com as informações da sua empresa.
                    </Typography>
                </Box>
                {/* <Divider /> */}
                <Termo mutate={mutate} order={data} />
                <Pagamento mutate={mutate} order={data} />
                {data?.Suborders.map(subOrder => (
                    <Pagamento mutate={mutate} title="Customizações e adicionais" order={data} subOrder={subOrder} key={subOrder.id} />
                ))}

                <CriarClube order={data} mutate={mutate} />
            </Stack>
        </Container>
    );
}

export function Plan() {
    const { planId } = useParams();

    const { data, isValidating } = useFetch(`subscription/plan/${planId}`);
    const navigation = useNavigate();
    const { setErrors, enqueueSnackbar } = useErrors();

    async function generateSubscription() {
        try {
            const { data } = await server.post(`subscription/`, {
                planId: planId,
            });
            console.log(data);
            navigation(`/assinatura/${data.data.uuid}`, { replace: true });
            enqueueSnackbar('Proposta gerada');
        } catch (error) {
            setErrors(error);
        }
    }

    if (isValidating) return <LoadingContent />;

    if (!data) return <>Plano não encontrado</>;

    return (
        <Container sx={{ maxWidth: { md: 800 }, paddingY: 6 }}>
            <Stack rowGap={2}>
                <Box component="img" src={Logo} width={200} height="auto" sx={{ textAlign: 'center' }} />
                <Card>
                    <CardContent>
                        <Stack rowGap={1}>
                            <Box component="img" width="100%" height={200} alt="Imagem do plano" />
                            <Divider />
                            <Typography variant="h4">{data.name || 'Nome do plano'}</Typography>
                            <Typography variant="body1">{data.description || 'Descrição sobre o plano'}</Typography>
                            <Typography variant="h4">
                                {Number(data.price || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                            <Divider />
                            <Button onClick={generateSubscription} variant="contained" sx={{ alignSelf: 'flex-end' }}>
                                Confirmar plano
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
}
