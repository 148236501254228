import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

export default function SelectCategory({ value, name, onChange, placeholder, label, error = null, helperText }) {
    const { data, mutate, error: errorFecth, isFetching } = useFetch(`category`, { params: { status: true, all: true } });

    const selected = data?.find(item => Number(value) === item.id) || null;

    return (
        <>
            <Autocomplete
                fullWidth
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                getOptionKey={e => e?.id}
                options={data || []}
                value={selected || null}
                name={name}
                loading={isFetching}
                onChange={(e, newValue) => onChange(name, String(newValue?.id))}
                renderInput={params => <TextField {...params} label={placeholder || label} error={!!error} helperText={helperText} />}
            />
            {!!errorFecth?.message && <FormHelperText>{errorFecth?.message}</FormHelperText>}
        </>
    );
}
