import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import AddImage from '../../../../components/AddImage';
import Address from '../../../../components/Address';
import SelectCategories from '../../../../components/SelectCategories';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';
import DocumentInputMasked from '../../../../components/DocumentInputMasked';

function Edit({ id, mutate }) {
    const { data } = useFetch(`advertiser/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const {setErrors, enqueueSnackbar} = useErrors();
    const { open, handleClose } = useDialog('#advertiser');

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            businessName: '',
            description: '',
            image: '',
            document: '',
            headOfficeId: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
            phone: '',
            areacode: '',
            latitude: '',
            longitude: '',
            address: '',
            status: true,
            listCategoryId: [],
            responsibleName: '',
            responsibleEmail: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`advertiser`, values);
                    enqueueSnackbar(`Anunciante ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`advertiser/${values.id}`, values);
                    enqueueSnackbar(`Anunciante ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            console.log(data)
            setValues(
                {
                    ...data,
                    address: `${data.street}, ${data.neighborhood}, ${data.city}-${data.state}`,
                    listCategoryId: data.Advertiser_category.map(i => i.categoryId),
                },
                true,
            );
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <DialogTitle>Adicionar/editar anunciante</DialogTitle>
                    {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                        <Help />
                    </IconButton> */}
                    <IconButton size="small" onClick={handleClose} sx={{marginRight: 2}}>
                        <Close />
                    </IconButton>
                </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2} component="form" onSubmit={handleSubmit}>
                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <AddImage onChange={setFieldValue} name="image" value={values.previewImage || values.image} />
                        </>
                    )}
                    <TextField onChange={handleChange} label="Nome do anunciante" name="name" value={values.name} />
                    <TextField onChange={handleChange} label="Razão social" name="businessName" value={values.businessName} />
                    <TextField 
                    onChange={(event) => {setFieldValue('document', event.target?.value || event)}}
                    label="Documento" 
                    name="document" 
                    value={values.document}
                    InputProps={{
                        inputComponent: DocumentInputMasked
                    }} />

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <TextField onChange={handleChange} label="Website" name="website" value={values.website} />
                        </>
                    )}

                    <TextField onChange={handleChange} label="Nome do responsável" name="responsibleName" value={values.responsibleName} />
                    <TextField onChange={handleChange} label="Email do responsável" name="responsibleEmail" value={values.responsibleEmail} />
                    <TextField onChange={handleChange} rows={10} multiline label="Sobre a loja" name="description" value={values.description} />

                    <Address
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        name="zipcode"
                        setFieldError={setFieldError}
                        value={values.zipcode}
                        address={values.address}
                    />
                    <Stack direction="row" columnGap={2}>
                        <TextField fullWidth onChange={handleChange} label="Complemento" name="complement" value={values.complement} />
                        <TextField sx={{ width: 150 }} onChange={handleChange} label="Número" name="number" value={values.number} />
                    </Stack>

                    <Stack direction="row" columnGap={2}>
                        <TextField fullWidth onChange={handleChange} label="Latitude" name="latitude" value={values.latitude} />
                        <TextField fullWidth onChange={handleChange} label="Longitude" name="longitude" value={values.longitude} />
                    </Stack>

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <SelectCategories value={values.listCategoryId} name="listCategoryId" onChange={setFieldValue} label="Selecione as categorias" />
                        </>
                    )}

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                label="Status anunciante"
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {Boolean(values.id) &&<Stack sx={{marginRight: 'auto'}}> <Typography fontSize={12}>Desde: {new Date(values.createdAt).toLocaleString('pt-BR')}</Typography>{values.contractExpiredAt && <Typography fontSize={12}>Contrato expirta em: {new Date(values.contractExpiredAt).toLocaleString('pt-BR')  }</Typography> }</Stack>}
                <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} loading={isSubmitting}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogAdvertiser({ mutate }) {
    const { open, id } = useDialog('#advertiser');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
