import { forwardRef, useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogPlans from './components/DialogPlan';
import { Button, Chip, IconButton, ListItemText, Paper, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { BlockOutlined, ChevronRight } from '@mui/icons-material';
import { useFetch } from '../../../hooks/useFecth';

export default forwardRef(function Plans(props, ref) {
    const [dialog, setDialog] = useState(false);
    const { data, error, mutate, isFetching } = useFetch('plans', { params: { type: 'plan', clubId: null } });
   
    const [selected, setSelected] = useState();

    const handleCloseDialog = () => {
        setDialog(false);
        setSelected();
    };

    const handleOpenDialog = item => {
        setDialog(true);
        setSelected(item);
    };

    useImperativeHandle(ref, () => {
        return {
            open() {
                setDialog(true);
            },
            close() {
                handleCloseDialog();
            },
        };
    });

    return (
        <Container sx={{ my: 4 }}>
            <Stack py={3}>
                <Typography variant="h2">Planos</Typography>
            </Stack>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" columnGap={2} p={2}>
                    <Button variant="outlined" component={Link} to={`#plans`}>
                        Adicionar plano
                    </Button>
                </Stack>
                <Divider />

                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md">
                            {data?.rows?.length === 0 ? (
                                <Paper sx={{ p: 4, border: 0 }}>
                                    <Stack direction="row" justifyContent="center" gap={1}>
                                        <Typography>Nenhum plano encontrado.</Typography>
                                        <BlockOutlined />
                                    </Stack>
                                </Paper>
                            ) : (
                                data?.rows?.map(item => (
                                    <>
                                        <ListItem
                                            key={`list_${item.id}`}
                                            secondaryAction={
                                                <IconButton component={Link} to={`#plans|${item.id}`} title="Abrir">
                                                    <ChevronRight />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText
                                                primary={item?.name}
                                                primaryTypographyProps={{ variant: 'title-md' }}
                                                secondary={
                                                    <Stack columnGap={1} direction="row" py={1}>
                                                        <Chip
                                                            size="small"
                                                            color={item.status ? 'success' : 'error'}
                                                            label={item.status ? 'Ativo' : 'Inativo'}
                                                        />
                                                        {Boolean(item.numberLifes) && (
                                                            <Chip
                                                                size="small"
                                                                color="primary"
                                                                label={`${item.numberLifes || 0} ${item.numberLifes === 1 ? 'vida' : 'vidas'}`}
                                                            />
                                                        )}
                                                    </Stack>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                ))
                            )}
                        </List>

                        {/* <React.Fragment key={`list_${item.id}`}>
                                    <ListItem 
                                    secondaryAction={
                                        <IconButton component={Link} to={`#additional|${item.id}`} title="Abrir">
                                             <ChevronRight />
                                         </IconButton>
                                    }>
                                        <ListItemButton component={Link} to={`#additional|${item.id}`}>
                                            <ListItemText>
                                                    <Typography variant="subtitle2">#{item.id}</Typography>
                                                    <Typography variant="title-md">{item?.name}</Typography>
                                                    <Chip size="small" label={item.status ? 'Ativo' : 'Inativo'} />
                                                </ListItemText>
                                            <ListItemIcon></ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment> */}
                    </Grid>
                </Grid>
            </Card>
            <DialogPlans mutate={mutate} />
        </Container>
    );
});
