import { Container, Divider, Paper, Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import Banners from './components/Banners';
import Featureds from './components/Featureds';
import MenuCarousel from './components/MenuCarousel';
export default function App() {
    const [tab, setTag] = useState('banners');
    return (
        <Container>
            <Stack py={3}>
                <Typography variant="h2">Aplicativo</Typography>
            </Stack>

            <Paper>
                <TabContext value={tab}>
                    <TabList onChange={(_, value) => setTag(value)}>
                        <Tab label="Banners" value="banners" />
                        <Tab label="Menu Carrossel" value="menu-carrossel" />
                        <Tab label="Feed Principal" value="feed-principal" />
                    </TabList>
                    <Divider />
                    <TabPanel value="banners" sx={{ p: 0 }}>
                        <Banners />
                    </TabPanel>
                    <TabPanel value="menu-carrossel" sx={{ p: 0 }}>
                        <MenuCarousel />
                    </TabPanel>
                    <TabPanel value="feed-principal" sx={{ p: 0 }}>
                        <Featureds />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Container>
    );
}
