import React from 'react';
import { IMaskInput } from 'react-imask';

const ZipCodeInputMasked = React.forwardRef(function ZipCodeInputMasked(props, ref) {
  const { onChange, name, ...other } = props;

  const mask = [
    { mask: '00000-000', maxLength: 8}, // CPF
  ];

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={mask}
      value={props.value?.toString() || ''}
      unmask={true} // Retorna apenas os números sem formatação
      onAccept={(newValue) => {
        // Envia um evento compatível com React
        onChange({
          target: {
            name, // Nome do campo
            value: newValue, // Valor atualizado
          },
        });
      }}
    />
  );
});

export default ZipCodeInputMasked;
