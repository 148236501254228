import { BlockOutlined, Cancel, Check, Clear, Close, FiberManualRecord, ToggleOff, ToggleOn } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Avatar, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AddImage from '../../../components/AddImage';
import useDialog from '../../../hooks/useDialog';
import useErrors from '../../../hooks/useErrors';
import { useFetch } from '../../../hooks/useFecth';
import server from '../../../services/server';

function Form({ data, mutate }) {
    // const { id } = useParams();
    const { open, handleClose } = useDialog('#banners');
    const {setErrors, enqueueSnackbar} = useErrors();
    const confirm = useConfirm();
    // const navigate = useNavigate()

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            title: '',
            mobileLink: '',
            isMobile: '',
            image: '',
            previewImage: '',
            status: true,
            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`club/settings/banners`, values);
                    // navigate(`#banners|${data?.data?.id}`, {replace: true})
                    enqueueSnackbar(`Banner adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`club/settings/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`club/settings/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    async function handleDelete() {
        try {
            await confirm({ title: 'Removendo banner', description: 'Você confirma que está removendo o banner?' });
            setFieldValue('action', 'delete');
            handleSubmit();
        } catch (e){
            setErrors(e);
        };
    }

    useEffect(() => {
        if (data) {
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginRight={2}>
                    <DialogTitle>Adicionando/editando banners</DialogTitle>
                        {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                            <Help />
                        </IconButton> */}
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField label="Nome do banner" onChange={handleChange} name="title" value={values.title} />
                    <FormControlLabel
                        control={<Switch checked={values?.isMobile} onChange={e => setFieldValue('isMobile', e.target.checked)} />}
                        orientation="horizontal"
                        label="Para o app"
                    />

                    {values.isMobile ? (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 300x150 pixels"
                            maxWidth={1000}
                            maxHeight={400}
                            canvaWidth={500}
                            canvaHeight={200}
                            colorLabel="error"
                        />
                    ) : (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 1000x300 pixels"
                            maxWidth={500}
                            maxHeight={200}
                            canvaWidth={500}
                            canvaHeight={150}
                            colorLabel="red"
                        />
                    )}

                    <TextField label="Link" onChange={handleChange} name="mobileLink" value={values.mobileLink} />

                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do banner"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {Boolean(values.id) && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    // const { id: clubId } = useParams();
    const { data } = useFetch(`club/settings/banners/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#banners');
    
    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}

export default function BannersClub() {
    // const { id } = useParams(); 
    const { data, mutate } = useFetch(`club/settings/banners`);
    // console.log('AQUI:', data)
    

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#banners" variant="outlined">
                        Adicionar banner
                    </Button>
                </Stack>
                <Card>
                    <List>
                        {!!data && !Boolean(data.length) && (
                            <Paper sx={{ p: 4, border: 0 }}>
                            <Stack direction="row" justifyContent="center" gap={1}>
                                <Typography>Nenhum resultado encontrado.</Typography>
                                <BlockOutlined />
                            </Stack>
                        </Paper>
                        )}
                        {data?.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#banners|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ borderRadius: 1, width: 90, height: 70, mr: 3 }} src={item.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.title}
                                        secondary={
                                            <>
                                                <Chip
                                                    sx={{ mt: 1 }}
                                                    label={item.isMobile ? 'Banner de Aplicativo' : 'Banner de Site'}
                                                    color={item.isMobile ? 'primary' : 'secondary'}
                                                    size="small"
                                                />
                                                <Chip
                                                    sx={{ mt: 1, ml: 1 }}
                                                    label={item.status ? 'Ativo' : 'Inativo'}
                                                    color={item.status ? 'success' : 'error'}
                                                    size="small"
                                                />
                                            </>
                                        }
                                    />
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}
