import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
} from '@mui/material';
import { useFetch } from '../../../../hooks/useFecth';
import useDialog from '../../../../hooks/useDialog';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import SelectCategory from '../../../../components/SelectCategory';
import { DateTimePicker } from '@mui/x-date-pickers';
import AddImage from '../../../../components/AddImage';
import server from '../../../../services/server';
import useErrors from '../../../../hooks/useErrors';
import * as yup from 'yup';
import { ChevronRight, ReorderRounded } from '@mui/icons-material';
import { Reorder, useDragControls } from 'motion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';

function EditFeatured({ id, mutate }) {
    const { data } = useFetch(`settings/featured/${id}`);
    return <FormFeatured data={data} mutate={mutate} />;
}

const schema = yup.object().shape({
    primaryTitle: yup.string().notRequired(),
    secondaryTitle: yup.string().notRequired(),
    categoryId: yup.string().required(),
    megaBanner: yup.string().notRequired(),
    expiredAt: yup.date().notRequired(),
    scheduledAt: yup.date().notRequired(),
});

function FormFeatured({ data, mutate }) {
    const { open, handleClose } = useDialog('#featured');
    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();
    const { values, errors, handleChange, setFieldValue, isSubmitting, handleSubmit, setValues } = useFormik({
        initialValues: {
            primaryTitle: '',
            secondaryTitle: '',
            categoryId: '',
            expiredAt: '',
            megaBanner: '',
            scheduledAt: '',
            action: 'create',
        },
        validationSchema: schema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (values.action === 'delete') {
                    await server.delete(`settings/featured/${values.id}`);
                    enqueueSnackbar('Item removido');
                    handleClose();
                } else {
                    if (values.id) {
                        await server.put(`settings/featured/${values.id}`, values);
                        enqueueSnackbar('Item alterado');
                    } else {
                        await server.post(`settings/featured`, values);
                        enqueueSnackbar('Item adicionado');
                    }
                }
                mutate(`settings/featured`);

                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data]);

    async function handleDelete() {
        try {
            await confirm({
                title: 'Removendo item',
                description: `Você tem certeza que deseja remover o item ${values.primaryTitle || values?.Category?.name || ''}?`,
            });
            setFieldValue('action', 'delete');
            handleSubmit();
        } catch (error) {}
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionar/Editar destaque {values.id}</DialogTitle>
            <DialogContent dividers>
                <Stack rowGap={2}>
                    <div>
                        <SelectCategory
                            value={values.categoryId}
                            onChange={setFieldValue}
                            name="categoryId"
                            label="Selecione a categoria"
                            error={!!errors.categoryId}
                            helperText={errors.categoryId}
                        />
                        <FormHelperText>Para cada categoria serão incluídas 9 anunciantes com suas respectivas ofertas</FormHelperText>
                    </div>
                    <div>
                        <TextField
                            label="Titulo principal"
                            onChange={handleChange}
                            name="primaryTitle"
                            fullWidth
                            value={values.primaryTitle}
                            error={!!errors.primaryTitle}
                            helperText={errors.primaryTitle}
                        />
                        <FormHelperText>Caso não informado o nome da categoria será utilizado</FormHelperText>
                    </div>
                    <TextField
                        label="Titulo secundário"
                        onChange={handleChange}
                        name="secondaryTitle"
                        fullWidth
                        value={values.secondaryTitle}
                        error={!!errors.secondaryTitle}
                        helperText={errors.secondaryTitle}
                    />
                    <DateTimePicker
                        value={values.scheduledAt}
                        format="dd/MM/yyyy HH:mm"
                        onChange={setFieldValue}
                        name="scheduledAt"
                        label="Iniciar em"
                        slotProps={{
                            textField: {
                                error: !!errors.scheduledAt,
                                helperText: errors.scheduledAt,
                                fullWidth: true,
                            },
                        }}
                    />
                    <DateTimePicker
                        value={values.expiredAt}
                        format="dd/MM/yyyy HH:mm"
                        onChange={setFieldValue}
                        name="expiredAt"
                        label="Finalizar em"
                        slotProps={{
                            textField: {
                                error: !!errors.expiredAt,
                                helperText: errors.expiredAt,
                                fullWidth: true,
                            },
                        }}
                    />
                    <AddImage
                        canvaWidth={420}
                        canvaHeight={200}
                        label="Adicione um mega banner (opcional)"
                        name="megaBanner"
                        value={values.previewImage || values.megaBanner}
                        onChange={setFieldValue}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleDelete} disabled={isSubmitting}>
                    Excluir
                </Button>
                <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogFeatured({ mutate }) {
    const { id, open } = useDialog('#featured');

    if (id && open) return <EditFeatured id={id} mutate={mutate} />;
    return <FormFeatured mutate={mutate} />;
}

function ListFeaturedItem({ item, handleStopDrag }) {
    const controls = useDragControls();
    const [isDragged, setIsDraged] = useState(false);
    return (
        <>
            <ListItem
                component={Reorder.Item}
                value={item.id}
                isDragged={isDragged}
                dragListener={false}
                dragControls={controls}
                sx={{ backgroundColor: 'Background', p: 2 }}
                onDragEnd={e => {
                    setIsDraged(true);
                    handleStopDrag();
                }}
                secondaryAction={
                    <IconButton component={Link} to={`#featured|${item.id}`} title="Abrir">
                        <ChevronRight />
                    </IconButton>
                }
            >
                <IconButton onMouseDown={e => setIsDraged(true)} size="small" onPointerDown={e => controls.start(e)} sx={{ mr: 1 }}>
                    <ReorderRounded fontSize="inherit" />
                </IconButton>
                <ListItemText primary={item.primaryTitle || item.Category?.name} />
            </ListItem>
            <Divider />
        </>
    );
}
function ListFeatured({ data, mutate, handleSendReorder }) {
    const handleReorder = useCallback(
        newOrder => {
            const reordered = newOrder
                .map((id, index) => {
                    const item = data.find(d => d.id === id);
                    return item ? { ...item, position: index } : null;
                })
                .filter(Boolean);
            mutate({ data: reordered }, false);
        },
        [data, mutate],
    );

    const handleStopDrag = useCallback(
        stopDraged => {
            handleSendReorder(data.map(i => i.id));
        },
        [data],
    );

    return (
        <List component={Reorder.Group} axis="y" values={data?.map(i => i.id) || []} onReorder={handleReorder}>
            {data?.map((item, k) => (
                <ListFeaturedItem key={`${item.id}`} item={item} handleStopDrag={handleStopDrag} />
            ))}
        </List>
    );
}

export default function Featureds() {
    const { data, mutate } = useFetch(`settings/featured`);
    const { enqueueSnackbar } = useErrors();
    async function handleSendReorder(reordered) {
        if (Array.isArray(reordered) && Boolean(reordered.length)) {
            console.log(reordered.length, reordered);
            await server.post(`settings/featured`, { reorder: reordered });
            enqueueSnackbar('Items reordenados');
        }
    }

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                <Button variant="outlined" component={Link} to="#featured">
                    Adicionar item
                </Button>
            </Stack>
            <Divider />
            {/*
                    <Paper sx={{ p: 4, border: 0 }}>
                        <Stack direction="row" justifyContent="center" gap={1}>
                            <BlockOutlined />
                            <Typography>Nenhum item encontrado.</Typography>
                        </Stack>
                    </Paper> */}
            <ListFeatured data={data || []} handleSendReorder={handleSendReorder} mutate={mutate} />

            <DialogFeatured mutate={mutate} />
        </>
    );
}
