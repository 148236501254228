import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { useFetch } from '../../../../hooks/useFecth';
import useDialog from '../../../../hooks/useDialog';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import server from '../../../../services/server';
import useErrors from '../../../../hooks/useErrors';
import * as yup from 'yup';
import { ChevronRight, CloseRounded, ReorderRounded } from '@mui/icons-material';
import { Reorder, useDragControls } from 'motion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import SelectIcon from '../../../../components/SelectIcon';
import SelectClubs from '../../../../components/SelectClubs';
import SelectCategory from '../../../../components/SelectCategory';
import SelectAdvertiser from '../../../../components/SelectAdvertiser';

function Edit({ id, mutate }) {
    const { data } = useFetch(`settings/menu/${id}`);
    return <Form data={data} mutate={mutate} />;
}

const schema = yup.object().shape({
    primaryTitle: yup.string().notRequired(),
    secondaryTitle: yup.string().notRequired(),
    categoryId: yup.string().required(),
    megaBanner: yup.string().notRequired(),
    expiredAt: yup.date().notRequired(),
    scheduledAt: yup.date().notRequired(),
});

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#menu');
    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            icon: '',
            label: '',
            type: '',
            link: 'link',
            isExternal: false,
            onWeb: false,
            onMobile: false,
            onDrawer: false,
            onCarousel: false,
            excludeClubs: [],
            status: '',

            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                if (values.action === 'delete') {
                    await server.delete(`settings/menu/${values.id}`);
                    enqueueSnackbar(`Item removido`);
                } else {
                    if (!Boolean(values.id)) {
                        await server.post(`settings/menu`, values);
                        enqueueSnackbar(`Item adicionado`);
                    } else {
                        await server.put(`settings/menu/${values.id}`, values);
                        enqueueSnackbar(`Item alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({
            title: 'Removendo menu',
            description: 'Você confirma que está removendo o item?',
        });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues({ ...data, type: data.type || 'link', action: 'update' }, true);
        }
    }, [data]);

    function handleChangeUrl(url) {
        if (!url.includes('https://')) {
            setFieldValue('link', `https://${url}`);
        } else {
            setFieldValue('link', url.replace('http://', 'https://'));
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>Adicionando/editando menu</DialogTitle>
                <IconButton size="small" onClick={handleClose} sx={{ marginRight: 2 }}>
                    <CloseRounded />
                </IconButton>
            </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
                        <SelectIcon onChange={setFieldValue} name="icon" value={values.icon} />
                        <TextField label="Label (Titulo)" onChange={handleChange} name="label" value={values.label} fullWidth />
                    </Stack>
                    {/* <TextField label="Link" onChange={handleChange} name="link" value={values.link} /> */}
                    <Stack direction="row" columnGap={1}>
                        <FormControl sx={{ width: 150 }}>
                            <InputLabel for="destino" size="small">
                                Destino
                            </InputLabel>
                            <Select id="destino" value={values.type} defaultValue="link" name="type" onChange={handleChange}>
                                <MenuItem value="link">Link/Url</MenuItem>
                                <MenuItem value="category">Categoria</MenuItem>
                                <MenuItem value="advertiser">Anunciante</MenuItem>
                                <MenuItem value="internal">Outros</MenuItem>
                            </Select>
                        </FormControl>

                        <>
                            {values.type === 'link' && (
                                <>
                                    <TextField fullWidth label="Link" onChange={handleChange} name="link" value={values.link} />
                                </>
                            )}
                            {values.type === 'category' && (
                                <>
                                    <SelectCategory label="Selecione a categoria" value={values.link} name="link" onChange={setFieldValue} />
                                </>
                            )}
                            {values.type === 'advertiser' && (
                                <>
                                    <SelectAdvertiser label="Selecione o anunciante" value={values.link} name="link" onChange={setFieldValue} />
                                </>
                            )}
                            {values.type === 'internal' && (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel for="destino" size="small">
                                            Página
                                        </InputLabel>
                                        <Select fullWidth value={values.link} placeholder='Selecione um item' name="link" onChange={handleChange}>
                                            <MenuItem value="Telemedicina">Telemedicina Eurobem</MenuItem>
                                            <MenuItem value="Telepsicologia">Telepsicologia Eurobem</MenuItem>
                                            <MenuItem value="Seguro">Seguro Eurobem</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        </>
                    </Stack>

                    <FormControlLabel
                        control={<Switch checked={values.onWeb} onChange={e => setFieldValue('onWeb', e.target.checked)} />}
                        label="Para o site"
                    />
                    <FormControlLabel
                        control={<Switch checked={values.onMobile} onChange={e => setFieldValue('onMobile', e.target.checked)} />}
                        label="Para o aplicativo"
                    />
                    {values.onMobile && (
                        <>
                            <FormControlLabel
                                control={<Switch checked={values.isExternal} onChange={e => setFieldValue('isExternal', e.target.checked)} />}
                                label="Abrir fora do app?"
                            />
                            <FormControlLabel
                                control={<Switch checked={values.onDrawer} onChange={e => setFieldValue('onDrawer', e.target.checked)} />}
                                label="No drawer?"
                            />
                            <FormControlLabel
                                control={<Switch checked={values.onCarousel} onChange={e => setFieldValue('onCarousel', e.target.checked)} />}
                                label="No carrossel?"
                            />
                        </>
                    )}
                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do item"
                    />
                    <SelectClubs value={values.excludeClubs} name="excludeClubs" onChange={setFieldValue} label="Não aparecer nos clubes" />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogMenu({ mutate }) {
    const { id, open, close } = useDialog('#menu');

    if (id && open) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}

function ListMenuItem({ item, handleStopDrag }) {
    const controls = useDragControls();
    const [isDragged, setIsDraged] = useState(false);
    return (
        <>
            <ListItem
                component={Reorder.Item}
                value={item.id}
                isDragged={isDragged}
                dragListener={false}
                dragControls={controls}
                onDragEnd={e => {
                    setIsDraged(false);
                    handleStopDrag();
                }}
                sx={{ backgroundColor: 'Background', p: 2 }}
                secondaryAction={
                    <IconButton component={Link} to={`#menu|${item.id}`} title="Abrir">
                        <ChevronRight />
                    </IconButton>
                }
            >
                <IconButton
                    onMouseDown={e => setIsDraged(true)}
                    size="small"
                    onPointerDown={e => {
                        console.log('PointerDown');
                        controls.start(e);
                    }}
                    sx={{ mr: 1 }}
                >
                    <ReorderRounded fontSize="inherit" />
                </IconButton>
                <ListItemText
                    primary={`${item.label}`}
                    secondary={
                        <Stack direction="row" columnGap={1}>
                            <Chip label={`#${item.id}`} size="small" />
                            {item.onMobile && <Chip size="small" label="App" />}
                            {item.onWeb && <Chip size="small" label="Web" />}
                        </Stack>
                    }
                />
            </ListItem>
            <Divider />
        </>
    );
}
export function ListMenu({ data, mutate, handleSendReorder }) {
    // const orderItems = data?.map(item => item.id);
    const handleReorder = useCallback(
        newOrder => {
            const reordered = newOrder
                .map((id, index) => {
                    const item = data.find(d => d.id === id);
                    return item ? { ...item, position: index } : null;
                })
                .filter(Boolean);
            mutate({ data: reordered }, false);
        },
        [data, mutate],
    );

    const handleStopDrag = useCallback(
        stopDraged => {
            handleSendReorder(data.map(i => i.id));
        },
        [data],
    );

    return (
        <List component={Reorder.Group} axis="y" values={data.map(i => i.id)} onReorder={handleReorder}>
            {data?.map((item, k) => (
                <ListMenuItem key={`${item.id}`} item={item} handleStopDrag={handleStopDrag} />
            ))}
        </List>
    );
}

export default function MenuCarousel() {
    const { data, mutate, isFetching } = useFetch(`settings/menu`, { params: { onCarousel: true } });
    const { enqueueSnackbar } = useErrors();

    async function handleSendReorder(reordered) {
        await server.post(`settings/menu`, { reorder: reordered });
        enqueueSnackbar('Itens reordenados');
    }

    if (isFetching) return null;
    return (
        <>
            <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                <Button variant="outlined" component={Link} to="#menu">
                    Adicionar item
                </Button>
            </Stack>
            <Divider />

            {data && <ListMenu data={data || []} handleSendReorder={handleSendReorder} mutate={mutate} />}

            <DialogMenu mutate={mutate} />
        </>
    );
}
