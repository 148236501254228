import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { useFetch } from '../../../../hooks/useFecth';
import useDialog from '../../../../hooks/useDialog';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import server from '../../../../services/server';
import useErrors from '../../../../hooks/useErrors';
import * as yup from 'yup';
import { ChevronRight, CloseRounded, ReorderRounded } from '@mui/icons-material';
import { Reorder, useDragControls } from 'motion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import SelectIcon from '../../../../components/SelectIcon';
import SelectClubs from '../../../../components/SelectClubs';

function Edit({ id, mutate, dialog, setDialog }) {
    const { data } = useFetch(`offer/${dialog.offerId}/instruction/${id}`);
    return <Form data={data} mutate={mutate} dialog={dialog} setDialog={setDialog} />;
}

const schema = yup.object().shape({
    primaryTitle: yup.string().notRequired(),
    secondaryTitle: yup.string().notRequired(),
    categoryId: yup.string().required(),
    megaBanner: yup.string().notRequired(),
    expiredAt: yup.date().notRequired(),
    scheduledAt: yup.date().notRequired(),
});

function Form({ data, mutate, setDialog, dialog }) {
    const { open } = dialog;

    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            icon: '',
            title: '',
            description: '',
            status: '',

            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                if (values.action === 'delete') {
                    await server.delete(`offer/${dialog?.offerId}/instruction/${values.id}`);
                    enqueueSnackbar(`Item removido`);
                } else {
                    if (!Boolean(values.id)) {
                        await server.post(`offer/${dialog?.offerId}/instruction/`, values);
                        enqueueSnackbar(`Item adicionado`);
                    } else {
                        await server.put(`offer/${dialog?.offerId}/instruction/${values.id}`, values);
                        enqueueSnackbar(`Item alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                setDialog(e => ({ ...e, open: false }));
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({
            title: 'Removendo menu',
            description: 'Você confirma que está removendo o item?',
        });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data]);

    function handleClose() {
        setDialog(e => ({ ...e, open: false }));
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>Adicionando/editando menu</DialogTitle>
                <IconButton size="small" onClick={handleClose} sx={{ marginRight: 2 }}>
                    <CloseRounded />
                </IconButton>
            </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
                        <SelectIcon onChange={setFieldValue} name="icon" value={values.icon} />
                        <TextField label="Titulo" onChange={handleChange} name="title" value={values.title} fullWidth />
                    </Stack>

                    <TextField label="Instrução" onChange={handleChange} name="description" value={values.description} fullWidth />
                    {/* <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do item"
                    /> */}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogInstructions({ mutate, dialog, setDialog }) {
    const { open, id } = dialog;

     
    if (id && open) return <Edit id={id} mutate={mutate} dialog={dialog} setDialog={setDialog} />;
    return <Form mutate={mutate} dialog={dialog} setDialog={setDialog} />;
}

function ListMenuItem({ item, handleStopDrag, dialog, setDialog }) {
    const controls = useDragControls();
    const [isDragged, setIsDraged] = useState(false);
    return (
        <>
            <ListItem
                component={Reorder.Item}
                value={item.id}
                key={item.id}
                isDragged={isDragged}
                dragListener={true}
                dragControls={controls}
                onDragEnd={e => {
                    setIsDraged(false);
                    handleStopDrag();
                }}
                sx={{ backgroundColor: 'Background', zIndex: isDragged? 1000: 'inherit' }}
                secondaryAction={
                    <IconButton onClick={e => setDialog(e => ({ ...e, open: true, id: item.id }))} title="Abrir">
                        <ChevronRight />
                    </IconButton>
                }
            >
                <IconButton
                    //onMouseDown={e => setIsDraged(true)}
                    size="small"
                    onPointerDown={e => {
                        console.log('PointerDown');
                        controls.start(e);
                    }}
                    sx={{ mr: 1 }}
                >
                    <ReorderRounded fontSize="inherit" />
                </IconButton>
                <ListItemText
                    primary={item.title || 'Sem título'}
                    secondary={item.description || 'Sem descrição'}
                />
            </ListItem>
            <Divider />
        </>
    );
}
export function ListMenu({ data, mutate, handleSendReorder, dialog, setDialog }) {
    // const orderItems = data?.map(item => item.id);
    const handleReorder = useCallback(
        newOrder => {
            const reordered = newOrder
                .map((id, index) => {
                    const item = data.find(d => d.id === id);
                    return item ? { ...item, position: index } : null;
                })
                .filter(Boolean);
            mutate({ data: reordered }, false);
        },
        [data, mutate],
    );

    const handleStopDrag = useCallback(
        stopDraged => {
            handleSendReorder(data.map(i => i.id));
        },
        [data],
    );

    return (
        <List component={Reorder.Group} axis="y" values={data.map(i => i.id)} onReorder={handleReorder}>
            {data?.map((item, k) => (
                <ListMenuItem key={`o-${item.id}`} item={item} handleStopDrag={handleStopDrag} dialog={dialog} setDialog={setDialog} />
            ))}
        </List>
    );
}

export default function ListInstructions({ offerId }) {
    const [dialog, setDialog] = useState({ open: false, id: null, offerId });
    const { data, mutate, isFetching } = useFetch(`offer/${offerId}/instruction`);
    const { enqueueSnackbar } = useErrors();

    async function handleSendReorder(reordered) {
        await server.post(`offer/${offerId}/instruction`, { reorder: reordered });
        enqueueSnackbar('Itens reordenados');
    }

    if (isFetching) return null;
    return (
        <>
            <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                <Button variant="outlined" onClick={e => setDialog(e => ({ ...e, open: true, id: null }))}>
                    Adicionar item
                </Button>
            </Stack>
            <Divider />

            {data && <ListMenu data={data || []} handleSendReorder={handleSendReorder} mutate={mutate} dialog={dialog} setDialog={setDialog} />}

            <DialogInstructions mutate={mutate} dialog={dialog} setDialog={setDialog} />
        </>
    );
}
