import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { FormHelperText } from '@mui/material';
import { TextField } from '@mui/material';
import { useState } from 'react';

export default function SelectAdvertiser({ value, name, onChange, placeholder, label, list }) {
    const { data, mutate, error, isFetching } = useFetch(`advertiser`, { params: { status: true, paged: false } });

    const selected = data?.find(item => Number(value)===item.id) || null;

    console.log(value, selected)

    return (
        <>
            <Autocomplete
                fullWidth
                placeholder={placeholder}
                getOptionLabel={e => e?.name}
                getOptionKey={e => e.id}
                options={data || []}
                value={selected}
                name={name}
                limitTags={3}
                loading={isFetching}
                onChange={(e, newValue) => onChange(name, String(newValue?.id))}
                renderInput={params => <TextField {...params} label={placeholder || label} />}
            />
            {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </>
    );
}
