import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useDialog from '../hooks/useDialog';
import useErrors from '../hooks/useErrors';
import server from '../services/server';
import SelectAdminPlans from '../components/SelectAdminPlans';
import SelectClubPlans from '../components/SelectClubPlans';

const route = {
    advertiser: 'anunciantes',
    club: 'clubes',
};
function Form({ mutate }) {
    const { setErrors, enqueueSnackbar } = useErrors();
    const { id: from, open, handleClose } = useDialog('#migration');
    const navigation = useNavigate();
    const { values, isSubmitting, setFieldValue, resetForm, handleChange, handleSubmit } = useFormik({
        initialValues: {
            id: '',
            planId: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post(`migration/${from}`, { id: values.id, planId: values.planId });
                enqueueSnackbar('Migração finalizada');
                mutate();
                resetForm();
                if (['advertiser', 'club'].includes(from)) {
                    navigation(`/dashboard/${route[from]}/${data.data.id}`, { replace: true });
                }
                setSubmitting(false);
            } catch (error) {
                resetForm();
                setSubmitting(false);
                setErrors(error);
                console.log(error);
            }
        },
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <Stack direction="row" justifyContent="space-between" alignItems="center" marginRight={2}>
                    <DialogTitle>Migração</DialogTitle>
                    {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                            <Help />
                        </IconButton> */}
                    <IconButton size="small" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                    <Stack rowGap={2}>
                        {['advertiser', 'club'].includes(from) && (
                            <>
                                <TextField label="ID de migração" value={values.id} name="id" type="number" onChange={handleChange} />
                                {from === 'club' && <SelectAdminPlans value={values.planId} name="planId" onChange={setFieldValue} />}
                            </>
                        )}

                        {['subscriber'].includes(from) && (
                            <>
                                <SelectClubPlans value={values.planId} name="planId" onChange={setFieldValue} placeholder="Selecione um plano para os usuários" />
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" disabled={isSubmitting} onClick={handleSubmit}>
                        Migrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default function DialogMigrate({ mutate }) {
    const { open } = useDialog('#migration');

    if (open) return <Form mutate={mutate} />;
}
