import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { useFetch } from '../../../../hooks/useFecth';
import useDialog from '../../../../hooks/useDialog';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import AddImage from '../../../../components/AddImage';
import server from '../../../../services/server';
import useErrors from '../../../../hooks/useErrors';
import * as yup from 'yup';
import { ChevronRight, CloseRounded, ReorderRounded } from '@mui/icons-material';
import { Reorder, useDragControls } from 'motion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import SelectCategory from '../../../../components/SelectCategory';
import SelectAdvertiser from '../../../../components/SelectAdvertiser';

function EditBanners({ id, mutate }) {
    const { data } = useFetch(`settings/banners/${id}`);
    return <FormBanners data={data} mutate={mutate} />;
}

const schema = yup.object().shape({
    title: yup.string().required(),
});

function FormBanners({ data, mutate }) {
    const { open, handleClose } = useDialog('#banners');
    const { setErrors, enqueueSnackbar } = useErrors();

    const confirm = useConfirm();
    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            title: '',
            link: '',
            type: '',
            isMobile: '',
            image: '',
            status: true,
            action: 'create',
        },
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`settings/banners`, values);
                    enqueueSnackbar(`Banner adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`settings/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`settings/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({ title: 'Removendo banner', description: 'Você confirma que está removendo o banner?' });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            setValues({ ...data, link: data.link || 'https://', type: data.type || 'link', action: 'update' }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginRight={2}>
                <DialogTitle>Adicionando/editando banners</DialogTitle>
                {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                            <Help />
                        </IconButton> */}
                <IconButton size="small" onClick={handleClose}>
                    <CloseRounded />
                </IconButton>
            </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField label="Nome do banner" onChange={handleChange} name="title" value={values.title} fullWidth />
                    <FormControlLabel
                        control={<Switch checked={values?.isMobile} onChange={e => setFieldValue('isMobile', e.target.checked)} />}
                        orientation="horizontal"
                        label="Para o app"
                    />

                    {values.isMobile ? (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 300x150 pixels"
                            maxWidth={1000}
                            maxHeight={400}
                            canvaWidth={500}
                            canvaHeight={200}
                            colorLabel="error"
                        />
                    ) : (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 1000x300 pixels"
                            maxWidth={500}
                            maxHeight={200}
                            canvaWidth={500}
                            canvaHeight={150}
                            colorLabel="red"
                        />
                    )}

                    <Stack direction="row" columnGap={1}>
                        <FormControl sx={{ width: 150 }}>
                            <InputLabel for="destino" size="small">
                                Destino
                            </InputLabel>
                            <Select id="destino" value={values.type} defaultValue="link" name="type" onChange={handleChange}>
                                <MenuItem value="link">Link/Url</MenuItem>
                                <MenuItem value="category">Categoria</MenuItem>
                                <MenuItem value="advertiser">Anunciante</MenuItem>
                                <MenuItem value="internal">Outros</MenuItem>
                            </Select>
                        </FormControl>

                        <>
                    
                            {values.type === 'link' && (
                                <>
                                    <TextField fullWidth label="Link" onChange={handleChange} name="link" value={values.link} />
                                </>
                            )}
                            {values.type === 'category' && (
                                <>
                                    <SelectCategory label="Selecione a categoria" value={values.link} name="link" onChange={setFieldValue} />
                                </>
                            )}
                            {values.type === 'advertiser' && (
                                <>
                                    <SelectAdvertiser label="Selecione o anunciante" value={values.link} name="link" onChange={setFieldValue} />
                                </>
                            )}
                            {values.type === 'internal' && (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel for="destino" size="small">
                                            Página
                                        </InputLabel>
                                        <Select fullWidth value={values.link} placeholder="Selecione um item" name="link" onChange={handleChange}>
                                            <MenuItem value="Telemedicina">Telemedicina Eurobem</MenuItem>
                                            <MenuItem value="Telepsicologia">Telepsicologia Eurobem</MenuItem>
                                            <MenuItem value="Seguro">Seguro Eurobem</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        </>
                    </Stack>

                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do banner"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogFeatured({ mutate }) {
    const { id, open, close } = useDialog('#banners');

    if (id && open) return <EditBanners id={id} mutate={mutate} />;
    return <FormBanners mutate={mutate} />;
}

function ListBannersItem({ item, handleStopDrag }) {
    const controls = useDragControls();
    const [isDragged, setIsDraged] = useState(false);
    return (
        <>
            <ListItem
                component={Reorder.Item}
                value={item.id}
                isDragged={isDragged}
                dragListener={false}
                dragControls={controls}
                onDragEnd={handleStopDrag}
                sx={{ backgroundColor: 'Background', p: 2 }}
                secondaryAction={
                    <IconButton component={Link} to={`#banners|${item.id}`} title="Abrir">
                        <ChevronRight />
                    </IconButton>
                }
            >
                <IconButton onMouseDown={e => setIsDraged(true)} size="small" onPointerDown={e => controls.start(e)} sx={{ mr: 1 }}>
                    <ReorderRounded fontSize="inherit" />
                </IconButton>
                <ListItemAvatar>
                    <Avatar sx={{ borderRadius: 1, width: 90, height: 40, mr: 2 }} src={item.image} />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
            </ListItem>
            <Divider />
        </>
    );
}
export function ListBanners({ data, mutate, handleSendReorder }) {
    const handleReorder = useCallback(
        newOrder => {
            const reordered = newOrder
                .map((id, index) => {
                    const item = data.find(d => d.id === id);
                    return item ? { ...item, position: index } : null;
                })
                .filter(Boolean);
            mutate({ data: reordered }, false);
        },
        [data, mutate],
    );

    const handleStopDrag = useCallback(
        stopDraged => {
            handleSendReorder(data.map(i => i.id));
        },
        [data],
    );

    return (
        <List component={Reorder.Group} axis="y" values={data?.map(i => i.id) || []} onReorder={handleReorder}>
            {data?.map((item, k) => (
                <ListBannersItem key={`${item.id}`} item={item} handleStopDrag={handleStopDrag} />
            ))}
        </List>
    );
}

export default function Banners() {
    const { data, mutate } = useFetch(`settings/banners`);
    const { enqueueSnackbar } = useErrors();
    async function handleSendReorder(reordered) {
        await server.post(`settings/banners`, { reorder: reordered });
        enqueueSnackbar('Itens reordenados');
    }

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                <Button variant="outlined" component={Link} to="#banners">
                    Adicionar banner
                </Button>
                {/* <Button variant="outlined" component={Link} to="#clubs">
                            Adicionar clube
                        </Button>
                        <ButtonGroup>
                            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                                <Search />
                            </Button>
                            {hasFilters && (
                                <Button color="primary" onClick={handleClearFilters}>
                                    <DeleteSweepRounded />
                                </Button>
                            )}
                        </ButtonGroup> */}
            </Stack>
            <Divider />
            {/* <Divider />
                    <Paper sx={{ p: 4, border: 0 }}>
                        <Stack direction="row" justifyContent="center" gap={1}>
                            <BlockOutlined />
                            <Typography>Nenhum item encontrado.</Typography>
                        </Stack>
                    </Paper> */}
            <ListBanners data={data || []} handleSendReorder={handleSendReorder} mutate={mutate} />

            <DialogFeatured mutate={mutate} />
        </>
    );
}
