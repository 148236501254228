import React, { useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Modal,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import Icon from '@mdi/react';
import * as mdiIcons from '@mdi/js';
import { ChevronLeft, ChevronLeftRounded, ChevronRightRounded, CloseRounded, EmojiEmotionsRounded } from '@mui/icons-material';

// Converte os ícones do @mdi/js em um array utilizável
const iconList = Object.entries(mdiIcons).map(([key, path]) => ({
    name: key.replace('mdi', ''), // Remove o prefixo 'mdi'
    path,
    icon: key
        .replace('mdi', '')
        .replace(/[A-Z]/g, match => `-${match.toLowerCase()}`)
        .replace('-', ''),
}));

const SelectIcon = ({ value, name, onChange, placeholder }) => {
    const selected = iconList.find(i => i.icon === value);
    const [paginate, setPaginate] = useState(0);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const handleSelectIcon = selectedIcon => {
        onChange(name, selectedIcon.icon);
        setOpen(false);
    };

    function up() {
        if (iconList.length / 20 === paginate) return;
        setPaginate(paginate + 20);
    }

    function down() {
        if (paginate === 0) return;
        setPaginate(paginate - 20);
    }

    const endpagination = current => {
        if (current === 0) return 20;
        return current + 20;
    };

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Tooltip title="Selecione um ícone">
                    <Button variant="outlined" onClick={() => setOpen(true)} sx={{ height: 40 }}>
                        {selected ? <Icon path={selected.path} size={1} /> : <EmojiEmotionsRounded />}
                    </Button>
                </Tooltip>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        Selecione um ícone
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseRounded />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack rowGap={2}>
                        <TextField label="Buscar" variant="outlined" onChange={e => setSearch(e.target.value)} fullWidth />
                        <Divider />

                        <Grid container spacing={2}>
                            {iconList
                                .filter(i =>
                                    i.name.toLocaleLowerCase().includes(
                                        search
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .toLocaleLowerCase(),
                                    ),
                                )
                                .slice(paginate, endpagination(paginate))
                                .map(
                                    (
                                        icon, // Limitando para performance
                                    ) => (
                                        <Grid item key={icon.name} xs={3} textAlign="center">
                                            <IconButton onClick={() => handleSelectIcon(icon)}>
                                                <Icon path={icon.path} size={1.2} />
                                            </IconButton>
                                            <Typography variant="body2" fontSize={12} sx={{ flexWrap: 'wrap' }}>
                                                {icon.name}
                                            </Typography>
                                        </Grid>
                                    ),
                                )}
                        </Grid>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={down}>
                        <ChevronLeftRounded />
                    </IconButton>
                    <IconButton onClick={up}>
                        <ChevronRightRounded />
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: 100 }}>
            <Autocomplete
                sx={{ width: 100 }}
                options={iconList}
                value={selected}
                getOptionLabel={option => option.name}
                onChange={(_, newValue) => newValue && onChange(name, newValue)}
                renderInput={params => <TextField {...params} label="Ícone" />}
                renderOption={(props, option) => (
                    <li {...props} key={option.name} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Icon path={option.path} size={1} />
                        {/* {option.name} */}
                    </li>
                )}
            />

            {/* Exibe o ícone selecionado */}
            {selected && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Icon path={selected.path} size={2} />
                    <span>{selected.name}</span>
                </div>
            )}
        </div>
    );
};

export default SelectIcon;
