import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';

export default function SelectPlan({ onChange, ...rest }) {
    const { name, value } = rest || {};

    const { data, mutate, isValidating } = useFetch(`plans`, { params: { paged: false } });
    const options =
        data?.map(({ id, name, price }) => ({
            id,
            label: `${name} - ${Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
        })) || [];
    const selected = options?.find(item => Number(value) === item.id) || null;

    return (
        <>
            <Autocomplete
                {...rest}
                //getOptionLabel={e => e?.name}
                options={options || []}
                value={selected || null}
                loading={isValidating}
                onChange={(e, newValue) => onChange({ target: { name, value: newValue?.id || null } })}
                renderInput={params => <TextField {...params} label="Selecione o plano" {...rest} />}
            />
        </>
    );
}
