import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, MenuItem, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
// import Address from '../../../../components/Address';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';
import PhoneInputMasked from '../../../../components/PhoneInputMasked';
import DocumentInputMasked from '../../../../components/DocumentInputMasked';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { Close } from '@mui/icons-material';
import SelectClubPlans from '../../../../components/SelectClubPlans';

function Edit({ id, clubId, mutate }) {
    const { data } = useFetch(`subscriber/${id}`);
    return <Form data={data} mutate={mutate} clubId={clubId} />;
}

function Form({ data, mutate, clubId }) {
    const { open, handleClose } = useDialog('#assinantes');
    const navigate = useNavigate()
    const { setErrors, enqueueSnackbar } = useErrors();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            email: '',
            document: '',
            gender: '',
            phone: '',
            birthday: '',
            clubId: clubId,
            status: '',
            planId: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (!Boolean(values.id)) {
                    const data = await server.post('subscriber', values);
                    navigate(`#assinantes|${data?.data?.id}`, {replace: true})
                    enqueueSnackbar(`Assinante ${values.name} adicionado.`);

                } else {
                    await server.put(`subscriber/${values.id}`, values);
                    enqueueSnackbar(`Assinante ${values.name} alterado.`);
                }
                mutate();
                handleClose();
            } catch (error) {
                setErrors(error);
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { id, name, email, document, gender, phone, birthday, clubId, status, planId } = data;
            const formattedBirthday = new Date(birthday).toLocaleDateString('en-CA');
    
            setValues({
                id,
                name,
                email,
                document,
                gender,
                phone,
                birthday: formattedBirthday,
                clubId,
                status,
                planId
            }, true);
        }
    }, [data, setValues]);

    const handleDelete = () => {
        setConfirmAction(() => async () => {
            try {
                await server.delete(`subscriber/${values.id}`);
                enqueueSnackbar('Assinante excluido com sucesso!');
                await mutate();
                handleClose()
            } catch (error) {
                setErrors('Erro ao deletar assinante.', error);
            }
            setConfirmOpen(false);
        });
        setConfirmOpen(true);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <Stack direction="row" justifyContent="space-between" alignItems="center" marginRight={2}>
                    <DialogTitle>Adicionando/editando assinante</DialogTitle>
                    {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                            <Help />
                        </IconButton> */}
                    <IconButton size="small" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                    <Stack gap={2}>
                        <TextField label="Nome" onChange={handleChange} placeholder="Nome" name="name" value={values.name} />
                        <TextField label="Email" onChange={handleChange} placeholder="Email" name="email" value={values.email} />
                        <Stack direction="row" gap={2}>
                            <TextField
                                label="Documento"
                                fullWidth
                                value={values.document}
                                onChange={event => {
                                    setFieldValue('document', event.target?.value || event);
                                }}
                                name="document"
                                placeholder="Documento"
                                InputProps={{
                                    inputComponent: DocumentInputMasked,
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Gênero"
                                onChange={handleChange}
                                name="gender"
                                value={values.gender}
                                select
                                variant="outlined"
                                placeholder="Gênero"
                            >
                                <MenuItem value="male">Masculino</MenuItem>
                                <MenuItem value="female">Feminino</MenuItem>
                            </TextField>
                        </Stack>
                        <TextField
                            label="Telefone"
                            fullWidth
                            value={values.phone}
                            onChange={event => {
                                setFieldValue('phone', event.target?.value || event);
                            }}
                            name="phone"
                            placeholder="Telefone"
                            InputProps={{
                                inputComponent: PhoneInputMasked,
                            }}
                        />
                        <TextField
                            label="Data de Aniversário"
                            onChange={handleChange}
                            placeholder="Data de Aniversário"
                            name="birthday"
                            type="date"
                            value={values.birthday}
                            InputLabelProps={{
                                shrink: true / false,
                            }}
                        />

                        <SelectClubPlans name="planId" onChange={setFieldValue} value={values.planId}  />
                        {Boolean(values.id) && (
                            <FormControlLabel
                                name="status"
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                orientation="horizontal"
                                label="Status do usuário"
                            />
                        )}
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    {Boolean(values.id) && (
                        <Button variant="outlined" color="error" onClick={handleDelete}>
                            Excluir
                        </Button>
                    )}
                    <Button variant="contained" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                        Salvar e continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationModal
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={confirmAction}
                title="Confirmação de Exclusão"
                message="Tem certeza de que deseja excluir este assinante?"
            />
        </>
    );
}

export default function DialogSubscribers({ mutate, clubId }) {
    const { open, id } = useDialog('#assinantes');
    if (open && id) return <Edit id={id} mutate={mutate} clubId={clubId} />;
    if (open) return <Form mutate={mutate} clubId={clubId} />;
}
