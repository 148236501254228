import { Close } from '@mui/icons-material';
import Help from '@mui/icons-material/Help';
import {
    Alert,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    Stack,
    Tab,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useNavigate, useParams } from 'react-router-dom';
import { useHelper } from '../../../../context/HelpSidebarContext';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ListInstructions from './ListInstructions';
import { DateTimePicker } from '@mui/x-date-pickers';

const CommissionField = React.forwardRef(function CommissionField(props, ref) {
    const { onChange, value, ...other } = props;
    return <IMaskInput {...other} value={String(value)} inputRef={ref} onAccept={value => onChange(props.name, value)} />;
});

function Edit({ id, mutate }) {
    const { id: advertiseId } = useParams();
    const { data } = useFetch(`offer/${id}`);
    return <Form data={data} mutate={mutate} />;
}

const isPercentual = is => {
    if (is) {
        return {
            mask: 'num%',
            unmask: true,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2, // Pode ajustar para mais precisão, se necessário
                    radix: ',',
                    min: 0,
                    max: 100,
                },
            },
        };
    } else {
        return {
            mask: 'R$ num',
            unmask: true,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: '.',
                    radix: ',',
                },
            },
        };
    }
};

function Form({ data, mutate }) {
    const { id } = useParams();
    const [tab, setTag] = useState('dados');
    const { open, handleClose } = useDialog('#offer');
    const { setErrors, enqueueSnackbar } = useErrors();
    const { openHelp } = useHelper();
    const navigation = useNavigate();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    const { values, handleSubmit, handleChange, setFieldValue, setValues, errors } = useFormik({
        initialValues: {
            name: '',
            label: '',

            description: '',
            privateDescription: '',
            isCoupon: false,
            isCashback: false,
            isVoucher: false,
            code: '',
            url: '',
            externalUrl: false,

            isTracked: false,
            avaliableQty: '',
            offerChannel: 'virtual',

            isPercentual: false,
            value: '',

            isCashbackPercentual: false,
            valueCashback: '',

            rules: '',
            image: '',
            megaBanner: '',

            file: '',
            productId: '',
            campaignId: '',
            expirationAt: '',
            status: true,
            advertiserId: id,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                // console.log("Valores antes de enviar:", values); // Exibe os valores que serão enviados
                setSubmitting(true);

                // Se não houver ID (nova oferta), faz um POST
                if (!values.id) {
                    const { data } = await server.post(`offer`, { ...values, value: Number(values.value) });
                    // console.log("Resposta do servidor:", data); // Exibe a resposta do servidor após o envio
                    enqueueSnackbar(`Oferta ${values.name} adicionada.`);
                    navigation(`#offer|${data.data.id}`, { replace: true });
                }

                // Se houver ID (oferta existente), faz um PUT
                if (values.id) {
                    const { data } = await server.put(`offer/${values.id}`, { ...values, value: Number(values.value) });
                    // console.log("Resposta do servidor:", data); // Exibe a resposta do servidor após o envio
                    enqueueSnackbar(`Oferta ${values.name} alterada.`);
                    // handleClose();
                }

                await mutate();
                setSubmitting(false);
            } catch (error) {
                console.log('Erro durante o envio:', error); // Exibe qualquer erro durante o envio
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const handleDelete = () => {
        setConfirmAction(() => async () => {
            try {
                await server.delete(`offer/${values.id}`);
                enqueueSnackbar('Oferta excluida com sucesso!');
                await mutate();
                handleClose();
            } catch (error) {
                setErrors('Erro ao deletar anunciante.', error);
            }
            setConfirmOpen(false);
        });
        setConfirmOpen(true);
    };

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('warning');

    const handleTypeOffer = (field, value) => {
        // setFieldValue(field, value);

        if (field === 'isVoucher' && (values.isCoupon || values.isCashback)) {
            setAlertMessage('Somente Cupom e Cashback podem ser selecionados juntos.');
            setAlertSeverity('warning');
            setAlertOpen(true);
            return; // Não permite a seleção do voucher
        }

        // Se o voucher não estiver sendo selecionado, permite a seleção do cupom ou cashback
        if (field === 'isCoupon') {
            setFieldValue('isCoupon', value);
            if (value) {
                setFieldValue('isVoucher', false); // Desmarcar voucher se cupom for selecionado
            }
        } else if (field === 'isCashback') {
            setFieldValue('isCashback', value);
            if (value) {
                setFieldValue('isVoucher', false); // Desmarcar voucher se cashback for selecionado
            }
        } else {
            setFieldValue(field, value); // Para o caso do voucher
        }
    };

    return (
        <TabContext value={tab}>
            {/* Alerta de sucesso ou erro */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} variant="filled">
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DialogTitle>{values.id ? 'Editando oferta' : 'Adicionando'} oferta</DialogTitle>
                    <Stack direction="row" columnGap={1} alignItems="center" px={2}>
                        <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                            <Help />
                        </IconButton>
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </Stack>

                <Divider />
                <TabList onChange={(_, value) => setTag(value)}>
                    <Tab label="Dados da oferta" value="dados" />
                    {Boolean(values.id) && <Tab label="Instruções e regras" value="instructions" />}
                </TabList>
                <DialogContent dividers sx={{ p: 0 }} component="form" onSubmit={handleSubmit}>
                    <TabPanel value="dados">
                        <Stack rowGap={2}>
                            {Boolean(values.id) && (
                                <>
                                    {/* <AddImage
                                    onChange={setFieldValue}
                                    name="image"
                                    value={values.previewImage || values.image}
                                    label="Dimensões 200x100 px"
                                /> */}
                                    {/* <AddImage
                                    onChange={setFieldValue}
                                    name="megaBanner"
                                    value={values.previewMegaBanner || values.megaBanner}
                                    label="Dimensões recomendadas 500x200px"
                                /> */}
                                </>
                            )}

                            <FormControl>
                                <FormLabel>Tipo de oferta</FormLabel>
                                <ToggleButtonGroup fullWidth>
                                    <ToggleButton
                                        value="isCoupon"
                                        selected={values.isCoupon}
                                        onChange={(e, value) => handleTypeOffer(value, !values.isCoupon)}
                                    >
                                        Cupom
                                    </ToggleButton>
                                    <ToggleButton
                                        value="isCashback"
                                        selected={values.isCashback}
                                        onChange={(e, value) => handleTypeOffer(value, !values.isCashback)}
                                    >
                                        Cashback
                                    </ToggleButton>
                                    <ToggleButton
                                        value="isVoucher"
                                        selected={values.isVoucher}
                                        onChange={(e, value) => handleTypeOffer(value, !values.isVoucher)}
                                    >
                                        Voucher
                                    </ToggleButton>
                                    {/* <ToggleButton value="isProduct" selected={values.isProduct} onChange={(e,value)=>handleTypeOffer(value, !values.isProduct)} disabled>
                                    Produto
                                </ToggleButton> */}
                                </ToggleButtonGroup>
                            </FormControl>

                            <TextField onChange={handleChange} label="Titulo da oferta" name="name" value={values.name} />
                            <TextField onChange={handleChange} label="Label" name="label" value={values.label} />

                            {/* <FormControl>
                            <InputLabel size="small">Tipo de oferta</InputLabel>
                            <Select label="Tipo de oferta" name="offerChannel" defaultValue="virtual" value={values.offerChannel} onChange={handleChange}>
                                <MenuItem value="physical">Físico</MenuItem>
                                <MenuItem value="virtual">Virtual</MenuItem>
                            </Select>
                        </FormControl> */}
                            {(values.isCashback || values.isCoupon) && (
                                <>
                                    <TextField required onChange={handleChange} label="Site da oferta" name="url" value={values.url} />
                                    {values.isCoupon && (
                                        <TextField
                                            fullWidth
                                            onChange={handleChange}
                                            label="Código do Cupom"
                                            name="code"
                                            value={values.code}
                                            error={!!errors.code}
                                            helperText={errors.code}
                                        />
                                    )}
                                    <FormControlLabel
                                        label="O site abre fora do app?"
                                        control={
                                            <Switch checked={values.externalUrl} onChange={e => setFieldValue('externalUrl', e.target.checked)} />
                                        }
                                    />
                                    {/* <FormControl orientation="horizontal">
                                            <InputLabel size="small">
                                                O site abre fora do app
                                                <IconButton size="small" onClick={e => openHelp('offer_externalUrl')}>
                                                    <Help />
                                                </IconButton>
                                            </InputLabel>
                                            
                                        </FormControl> */}
                                </>
                            )}
                            <TextField
                                rows={3}
                                multiline
                                onChange={handleChange}
                                label="Descrição da oferta"
                                name="description"
                                value={values.description}
                            />
                            
                            {/* <InputLabel size="small">
                                    Regras
                                    <IconButton size="small" onClick={e => openHelp('offer_rules')}>
                                        <Help />
                                    </IconButton>
                                </InputLabel> */}
                            <TextField rows={2} multiline onChange={handleChange} label="Regras gerais" name="rules" value={values.rules} />

                            {/* <InputLabel size="small">
                                    Quantidade disponível
                                    <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                                        <Help />
                                    </IconButton>
                                </InputLabel> */}
                            <TextField
                                type="number"
                                onChange={handleChange}
                                label="Quantidade disponível"
                                name="avaliableQty"
                                value={values.avaliableQty}
                            />

                            {/* <TextField onChange={handleChange} label="Valor do desconto" name="value" value={values.value} /> */}

                            {(values.isCoupon || values.isVoucher) && (
                                <TextField
                                    fullWidth
                                    label="Valor Desconto Cupom"
                                    name="value"
                                    value={String(values.value)}
                                    onChange={setFieldValue}
                                    error={!!errors.value}
                                    helperText={errors.value}
                                    InputProps={{
                                        inputProps: { ...isPercentual(values.isPercentual) },
                                        inputComponent: CommissionField,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormControlLabel
                                                    sx={{ m: 0, p: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={values.isPercentual}
                                                            onChange={e => setFieldValue('isPercentual', e.target.checked)}
                                                        />
                                                    }
                                                    label="%"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {values.isCashback && (
                                <TextField
                                    fullWidth
                                    label="Valor Desconto Cashback"
                                    name="valueCashback"
                                    value={String(values.valueCashback)}
                                    onChange={setFieldValue}
                                    error={!!errors.valueCashback}
                                    helperText={errors.valueCashback}
                                    InputProps={{
                                        inputProps: { ...isPercentual(values.isCashbackPercentual) },
                                        inputComponent: CommissionField,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormControlLabel
                                                    sx={{ m: 0, p: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={values.isCashbackPercentual}
                                                            onChange={e => setFieldValue('isCashbackPercentual', e.target.checked)}
                                                        />
                                                    }
                                                    label="%"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {/* <FormControlLabel
                            control={<Switch checked={values.isTracked} onChange={e => setFieldValue('isTracked', e.target.checked)} />}
                            label="Oferta trackeada"
                        />

                        */}

                            {Boolean(values.id) && (
                                <>
                                    <DateTimePicker
                                        value={values.expirationAt ? new Date(values.expirationAt) : null}
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={e => setFieldValue('expirationAt', e)}
                                        name="expirationAt"
                                        label="Expira em"
                                        slotProps={{
                                            textField: {
                                                error: !!errors.expirationAt,
                                                helperText: errors.expirationAt,
                                                fullWidth: true,
                                            },
                                            field: { clearable: true, onClear: () => setFieldValue('expirationAt', '') },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                        label="Status da oferta"
                                    />
                                </>
                            )}
                            <Divider> Dados internos </Divider>
                            <TextField
                                rows={3}
                                multiline
                                onChange={handleChange}
                                label="Observações (Privado)"
                                name="privateDescription"
                                value={values.privateDescription}
                            />
                        </Stack>
                    </TabPanel>

                    <TabPanel value="instructions">
                        {/* <Stack rowGap={2}>
                                <Button sx={{alignSelf: 'flex-end'}} variant='outlined'>Adicionar item</Button>
                                 
                                <List >
                                    <ListItem variant="outlined">
                                        <ListItemText primary="Instrução 1" primaryTypographyProps={{ variant: 'h4' }} secondary="Instrução 2" />
                                    </ListItem>
                                    
                                </List>
                            </Stack> */}

                        <ListInstructions offerId={values.id} mutate={mutate} />
                    </TabPanel>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {Boolean(values.id) && (
                            <Box>
                                <Button variant="outlined" color="error" onClick={handleDelete}>
                                    Excluir
                                </Button>
                            </Box>
                        )}
                        <Box>
                            <Button variant="contained" onClick={handleSubmit}>
                                Salvar e continuar
                            </Button>
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>
            <ConfirmationModal
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={confirmAction}
                title="Confirmação de Exclusão"
                message="Tem certeza de que deseja excluir esta oferta?"
            />
        </TabContext>
    );
}

export default function DialogOffer({ mutate }) {
    const { open, id } = useDialog('#offer');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
