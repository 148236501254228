import { BlockOutlined, Close } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Switch,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';
import SelectIcon from '../../../../components/SelectIcon';
import { ListMenu } from '../../App/components/MenuCarousel';

function Form({ data, mutate }) {
    const { id } = useParams();
    const { open, handleClose } = useDialog('#menu');
    const { setErrors, enqueueSnackbar } = useErrors();
    const confirm = useConfirm();

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            icon: '',
            label: '',
            link: '',
            isExternal: false,
            onWeb: false,
            onMobile: false,
            onDrawer: false,
            onCarousel: false,

            status: '',

            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                if (values.action === 'delete') {
                    await server.delete(`clubs/${id}/menu/${values.id}`);
                    enqueueSnackbar(`Item removido`);
                } else {
                    if (!Boolean(values.id)) {
                        await server.post(`clubs/${id}/menu`, values);
                        enqueueSnackbar(`Item adicionado`);
                    } else {
                        await server.put(`clubs/${id}/menu/${values.id}`, values);
                        enqueueSnackbar(`Item alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    async function handleDelete() {
        try {
            await confirm({
                title: 'Removendo menu',
                description: 'Você confirma que está removendo o item?',
            });
            setFieldValue('action', 'delete');
            handleSubmit();
        } catch(e) {
            setErrors(e);
        };
    };

    useEffect(() => {
        if (data) {
            console.log(data)
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>Adicionando/editando menu</DialogTitle>
                <IconButton size="small" onClick={handleClose} sx={{ marginRight: 2 }}>
                    <Close />
                </IconButton>
            </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
                        <SelectIcon onChange={setFieldValue} name="icon" value={values.icon} />
                        <TextField label="Label (Titulo)" onChange={handleChange} name="label" value={values.label} fullWidth />
                    </Stack>
                    <TextField label="Link" onChange={handleChange} name="link" value={values.link} />

                    <FormControlLabel
                        control={<Switch checked={values.onWeb} onChange={e => setFieldValue('onWeb', e.target.checked)} />}
                        label="Para o site"
                    />
                    <FormControlLabel
                        control={<Switch checked={values.onMobile} onChange={e => setFieldValue('onMobile', e.target.checked)} />}
                        label="Para o aplicativo"
                    />
                    {values.onMobile && (
                        <>
                            <FormControlLabel
                                control={<Switch checked={values.isExternal} onChange={e => setFieldValue('isExternal', e.target.checked)} />}
                                label="Abrir fora do app?"
                            />
                            <FormControlLabel
                                control={<Switch checked={values.onDrawer} onChange={e => setFieldValue('onDrawer', e.target.checked)} />}
                                label="No drawer?"
                            />
                            <FormControlLabel
                                control={<Switch checked={values.onCarousel} onChange={e => setFieldValue('onCarousel', e.target.checked)} />}
                                label="No carrossel?"
                            />
                        </>
                    )}
                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do item"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    const { id: clubId } = useParams();
    const { data } = useFetch(`clubs/${clubId}/menu/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#menu');

    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}

export default function MenuClub() {
    const { id } = useParams();
    const { data = [], mutate } = useFetch(`clubs/${id}/menu`);
    const { enqueueSnackbar } = useErrors();

    async function handleSendReorder(reordered) {
        await server.post(`clubs/${id}/menu`, { reorder: reordered });
        enqueueSnackbar('Itens reordenados');
    }


    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#menu" variant="outlined">
                        Adicionar Menu
                    </Button>
                </Stack>
                <Card>
                    <ListMenu data={data}  mutate={mutate} handleSendReorder={handleSendReorder}/>
                    {/* <List>
                        {(!data || data.length === 0) && (
                            <Paper sx={{ p: 4, border: 0 }}>
                                <Stack direction="row" justifyContent="center" gap={1}>
                                    <Typography>Nenhum resultado encontrado.</Typography>
                                    <BlockOutlined />
                                </Stack>
                            </Paper>
                        )}
                        {data.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#menu|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={item.label}
                                        primaryTypographyProps={{ variant: 'h4' }}
                                        secondary={
                                            <>
                                                <Stack direction="row" gap={1} mt={1}>
                                                    {item.onMobile && <Chip size="small" label="Aplicativo" color="primary" />}
                                                    {item.onWeb && <Chip size="small" label="Site" color="primary" />}
                                                    {item.status ? (
                                                        <Chip size="small" label="Ativo" color="success" />
                                                    ) : (
                                                        <Chip size="small" label="Inativo" color="error" />
                                                    )}
                                                </Stack>
                                            </>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List> */}
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}
