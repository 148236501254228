import { Close } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';
import AddImage from '../../../../components/AddImage';
import ConfirmationModal from '../../../../components/ConfirmationModal'; // Importando o modal de confirmação
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';
import { useConfirm } from 'material-ui-confirm';

const PriceField = React.forwardRef(function PriceField(props, ref) {
    const { onChange, value, type, ...other } = props;
    return (
        <IMaskInput
            {...other}
            value={String(value)}
            inputRef={ref}
            onAccept={value => onChange(props.name, value)}
            mask={'R$ num'}
            unmask={true}
            blocks={{
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: '.',
                    radix: ',',
                },
            }}
        />
    );
});

function Edit({ id, mutate }) {
    const { data } = useFetch(`plans/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function ListProductsKit({ selected = [], setFieldValue }) {
    const { data, error } = useFetch(`additional`, { params: { paged: false, status: true } });

    if (error) return null;

    function handleSelect(id) {
        if (selected.includes(id)) {
            const newlist = selected.filter(i => i !== id);
            setFieldValue('product_kit', newlist);
        } else {
            setFieldValue('product_kit', [...selected, id]);
        }
    }

    return (
        <List>
            <>
                <Divider sx={{ mb: 2 }}>Produtos de customização</Divider>
                {Boolean(data) &&
                    Array.isArray(data) &&
                    data
                        ?.filter(item => item.type === 'custom')
                        .map(item => (
                            <>
                                <ListItem>
                                    <ListItemText primary={item.name} />
                                    <ListItemSecondaryAction>
                                        <Checkbox onChange={(e, v) => handleSelect(item.id)} checked={selected.includes(item.id)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                <Divider sx={{ mb: 2, mt: 3 }}>Produtos adicionais</Divider>
                {Boolean(data) &&
                    Array.isArray(data) &&
                    data
                        ?.filter(item => item.type === 'additional')
                        .map(item => (
                            <>
                                <ListItem>
                                    <ListItemText primary={item.name} />
                                    <ListItemSecondaryAction>
                                        <Checkbox onChange={(e, v) => handleSelect(item.id)} checked={selected.includes(item.id)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
            </>
        </List>
    );
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#plans');
    const { setErrors, enqueueSnackbar } = useErrors();
    const navigate = useNavigate();
    const [tab, setTab] = useState('#dados');
    const confirm = useConfirm();

    const { values, handleSubmit, handleChange, setFieldValue, touched, errors, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            description: '',
            // additionalType: '',
            price: 0,
            // disccount: 0,
            numberLifes: 1,
            image: '',
            functionsClub: [],
            product_kit: [],
        },

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (!values.id) {
                    const { data } = await server.post(`plans`, values);
                    enqueueSnackbar(`Plano ${values.name} adicionado`);
                    navigate(`#plans|${data.data.id}`);

                    handleClose();
                } else {
                    await server.put(`plans/${values.id}`, values);
                    enqueueSnackbar(`Plano ${values.name} alterado`);
                    // handleClose();
                }
                await mutate();
            } catch (error) {
                setErrors(error);
            } finally {
                setSubmitting(false);
                handleClose();
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues({ ...data, functionsClub: data?.functionsClub || [], product_kit: data?.Kit?.map(i => i.productId, []) }, true);
        }
    }, [data]);

    // Função chamada quando a exclusão é confirmada
    const handleDeleteConfirm = async () => {
        try {
            await confirm({ title: 'Removendo registro', description: 'Deseja remover o plano ?' });
            await server.delete(`plans/${values.id}`);
            enqueueSnackbar('Plano excluído com sucesso', { variant: 'success' });
            handleClose();
            await mutate();
        } catch (error) {
            setErrors(error);
        }
    };

    return (
        <>
            <TabContext value={tab}>
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <DialogTitle>{data ? 'Editando plano' : 'Adicionando plano'}</DialogTitle>
                        <IconButton onClick={handleClose} aria-label="close" sx={{ marginRight: 2 }}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Divider />
                    {values.id && (
                        <>
                            <TabList onChange={(e, tab) => setTab(tab)}>
                                <Tab value="#dados" label="Dados do plano" />
                                <Tab value="#adicionais" label="Produtos do plano" />
                            </TabList>
                            <Divider />
                        </>
                    )}
                    <DialogContent dividers sx={{ p: 0 }}>
                        <TabPanel value="#dados">
                            <Stack spacing={2}>
                                {values.id && (
                                    <AddImage
                                        value={values.previewImage || values.image}
                                        onChange={setFieldValue}
                                        name="image"
                                        label="Adicionar imagem"
                                    />
                                )}
                                <TextField
                                    label="Nome do plano"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    // error={Boolean(touched.name && errors.name)}
                                    // helperText={touched.name && errors.name}
                                />
                                <TextField
                                    label="Descrição do plano"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />

                                <ToggleButtonGroup
                                    fullWidth
                                    onChange={(e, value) => setFieldValue('functionsClub', value)}
                                    value={values.functionsClub}
                                    name="functionsClub"
                                >
                                    <ToggleButton sx={{ fontSize: 11 }} disabled={values?.functionsClub.includes('custom_app')} value="multi_app">
                                        App Eurobem
                                    </ToggleButton>
                                    {/* <ToggleButton sx={{ fontSize: 11 }}  disabled={values?.functionsClub.includes('multi_app')}  value="custom_app">
                                        App personalizado
                                    </ToggleButton> */}
                                    <ToggleButton sx={{ fontSize: 11 }} value="sale_page">
                                        Página de vendas
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: 11 }} value="cupons">
                                        Rede de descontos
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: 11 }} value="vouchers">
                                        Rede local
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: 11 }} value="cashback">
                                        Cashback
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {values.id && (
                                    <>
                                        <TextField
                                            label="Preço"
                                            name="price"
                                            value={String(values.price)}
                                            onChange={setFieldValue}
                                            error={Boolean(touched.price && errors.price)}
                                            helperText={touched.price && errors.price}
                                            type="number"
                                            InputProps={{
                                                inputComponent: PriceField,
                                            }}
                                            InputLabelProps={{
                                                shrink: true / false,
                                            }}
                                        />
                                        <TextField
                                            label="Número de vidas"
                                            name="numberLifes"
                                            value={values.numberLifes}
                                            onChange={handleChange}
                                            error={Boolean(touched.numberLifes && errors.numberLifes)}
                                            helperText={touched.numberLifes && errors.numberLifes}
                                            type="number"
                                        />
                                    </>
                                )}
                            </Stack>
                        </TabPanel>
                        <TabPanel value="#adicionais">
                            <DialogContent>
                                {/* <Alert>Colocar aqui a lista de produtos adicionais ordenados por categoria</Alert> */}
                                <ListProductsKit selected={values?.product_kit} setFieldValue={setFieldValue} />
                            </DialogContent>
                        </TabPanel>
                    </DialogContent>

                    <DialogActions>
                        {values.id && (
                            <Button color="error" onClick={handleDeleteConfirm}>
                                Excluir
                            </Button>
                        )}
                        <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>
            </TabContext>
        </>
    );
}

export default function DialogPlans({ mutate }) {
    const { open, id } = useDialog('#plans');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
