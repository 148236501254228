import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function SelectClubPlans({ value, name, onChange, placeholder, type }) {
    // const [options, setOptions] = useState([]);
    const { id } = useParams();
    const { data, mutate, error, isFetching } = useFetch(`clubs/${id}/plans`);

    // useEffect(() => {
    //     if (data && data.rows) {
    //         setOptions(prev => {
    //             const combinedOptions = [...prev, ...data.rows];
    //             const uniqueOptions = combinedOptions.reduce((acc, current) => {
    //                 if (!acc.some(item => item.id === current.id)) {
    //                     acc.push(current);
    //                 }
    //                 return acc;
    //             }, []);
    //             return uniqueOptions;
    //         });
    //     }
    // }, [data]);

    const selected = data?.find(item => Number(value) === item.id) || null;

    return (
        <>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                options={data || []}
                value={selected || null}
                name={name}
                loading={isFetching}
                onChange={(e, newValue) => onChange(name, newValue?.id)}
                renderInput={params => <TextField {...params} label={placeholder || 'Selecione o plano'} />}
            />
            <FormHelperText>{error?.message}</FormHelperText>
        </>
    );
}
