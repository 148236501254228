import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';

export default function SelectAdminPlans({ value, name, onChange, placeholder, type }) {
    const [options, setOptions] = useState([]);
    const { data, mutate, error, isFetching } = useFetch(`plans`);

    useEffect(() => {
        if (data && data.rows) {
            setOptions(prev => {
                const combinedOptions = [...prev, ...data.rows];
                const uniqueOptions = combinedOptions.reduce((acc, current) => {
                    if (!acc.some(item => item.id === current.id)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                return uniqueOptions;
            });
        }
    }, [data]);
    
    const selected = options?.filter( item=> Number(value) === item.id)[0] || null

    return (
        <>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                options={options}
                value={selected || null}
                name={name}
                loading={isFetching}
                onChange={(e, newValue) => onChange(name, newValue?.id)}
                renderInput={(params) => <TextField {...params} label="Selecione o plano" />}            />
            <FormHelperText>{error?.message}</FormHelperText>
        </>
    );
}
