import { BlockOutlined, ChevronRight } from '@mui/icons-material';
import { Button, Chip, IconButton, ListItemText, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFecth';
import DialogPlans from './components/DialogAditional';


export default function Aditionals(props, ref) {
    const { data, error, mutate, isFetching } = useFetch('additional');

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Adicionais</Typography>
            </Box>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" p={2}>
                    <Button variant="outlined" component={Link} to="#additional">
                        Adicionar produto
                    </Button>
                </Stack>
                <Divider />
                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 2 }}>
                            {data?.rows?.length === 0 ? (
                                <Paper sx={{ p: 4, border: 0 }}>
                                    <Stack direction="row" justifyContent="center" gap={1}>
                                        <Typography>Nenhum dado encontrado.</Typography>
                                        <BlockOutlined />
                                    </Stack>
                                </Paper>
                            ) : (
                                data?.rows?.map(item => (
                                    <React.Fragment key={`list_${item.id}`}>
                                        <ListItem
                                            secondaryAction={
                                                <IconButton component={Link} to={`#additional|${item.id}`} title="Abrir">
                                                    <ChevronRight />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText>
                                                <Typography variant="subtitle2">#{item.id}</Typography>
                                                <Typography variant="title-md">{item?.name}</Typography>
                                                <Chip size="small" label={item.status ? 'Ativo' : 'Inativo'} />
                                            </ListItemText>
                                            <ListItemIcon></ListItemIcon>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogPlans mutate={mutate} />
        </Container>
    );
}
