import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import useErrors from '../../hooks/useErrors';
import server from '../../services/server';

export default function ClubAuth() {
    const navigation = useNavigate();
    const { setErrors } = useErrors();

    const { values, handleSubmit, handleChange } = useFormik({
        initialValues: {
            username: '',
            password: '',
            accessCode: '',
        },
        onSubmit: async values => {
            try {
                const { data } = await server.post('club/auth', values);
                navigation(`/auth/?token=${data.data.token}&refreshtoken=${data.data.refreshToken}&club=${values.accessCode}`, { replace: true });
            } catch (error) {
                setErrors(error);
            }
        },
    });

    const handleLoginGoogle = async () => {
        try {
            window.open(`${process.env.REACT_APP_SERVER}auth/signin/google`, '_self');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Stack component="form" alignItems="center" justifyContent="center" onSubmit={handleSubmit}>
            <Box width={300}>
                <Box component="img" mr="auto" ml="auto" src={Logo} sx={{ width: 180, marginBottom: 4 }} />
                <Stack gap={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={values.accessCode}
                        label="Código de Acesso"
                        onChange={handleChange}
                        name="accessCode"
                        InputLabelProps={{
                            shrink: true / false,
                        }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={values.username}
                        label="Usuário"
                        onChange={handleChange}
                        name="username"
                        InputLabelProps={{
                            shrink: true / false,
                        }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={values.password}
                        type="password"
                        label="Senha"
                        onChange={handleChange}
                        name="password"
                        InputLabelProps={{
                            shrink: true / false,
                        }}
                    />
                    <Button fullWidth onClick={handleSubmit} type="submit" variant="contained" sx={{ my: 2 }}>
                        Entrar
                    </Button>
                </Stack>
            </Box>
        </Stack>
    );
}
